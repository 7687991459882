import React, { Component, useState, useEffect, useContext } from "react";
import { Image } from "react-bootstrap";
import {
  LASTFM_URL_BASE,
  LASTFM_COUNTRY,
  LASTFM_API_KEY_ALT,
  LASTFM_METHOD_GEO_GETTOPARTISTS,
  LASTFM_METHOD_GEO_GETTOPTRACKS,
  LASTFM_METHOD_CHART_GETTOPTRACKS,
  LASTFM_URL_IMG,
  DEEZER_CHART_PODCASTS,
} from "../../config/config";
import Moment from "react-moment";
import { Link } from "react-router-dom";

import "../../index.css";

import { data } from "jquery";
import SpotifyContext from "../Spotify/SpotifyContext";

import datos from '../../../public/datos.json';

function Toprecords() {




  useEffect(() => {

  }, []);

 

  


  return (
      <div className="row">
        

        <div className="col-md-12 col-xs-12 col-sm ">
          <h2 className="text-center">Top Albums</h2>
          <div className="list-group w-100">

            {datos.slice(0,10).map((disco) => {

                  return (
                    <a target="_blank" href="#">
                      <div
                        className="list-group-item list-group-item-action flex-column align-items-start"
                        key={disco.id}
                      >
                        <div className="d-flex align-items-center">
                          <img
                            src={"https://firebasestorage.googleapis.com/v0/b/elperrorabioso-7faa1.appspot.com/o/"+disco.id+".jpg?alt=media"}
                            alt={disco.disco}
                            key={disco.id}
                            width="67px"
                            height="67px"
                            className="mr-2"
                          />
                          <div className="mr-3 ml-3 posicion">{disco.posicion}</div>
                          <div className="ms-3">
                            <p className="fw-bold mb-1">{disco.disco}</p>
                            <p className="text-muted mb-0">
                             {disco.autor}
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  );


              } )
            }
          </div>
          </div>

      </div>
  );
}

export default Toprecords;
