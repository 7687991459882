import React, { Component } from "react";
import { Image } from "react-bootstrap";
import {
  URL_SEARCH,
  URL_DETAIL,
  API_KEY,
  API_KEY_ALT,
  API_LANG,
  URL_IMG,
  IMG_SIZE_LARGE,
  IMG_SIZE_SMALL,
  IMG_SIZE_XLARGE,
  RELEASE_DATE_DESC,
  RELEASE_DATE_ASC,
} from "../../config/config";
import Moment from "react-moment";

import { compose } from "recompose";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import TabContainer from "react-bootstrap/TabContainer";
import TabContent from "react-bootstrap/TabContent";
import TabPane from "react-bootstrap/TabPane";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";

import { Link, withRouter } from "react-router-dom";
import { createBrowserHistory } from "history";

import Reparto from "../Reparto";
import Equipo from "../Equipo";

import Trailer from "../Trailer";
import LikeMovieButton from "../LikeMovieButton";

import StarRatingComponent from "react-star-rating-component";
import Flag from "react-flags";

import "../../index.css";

import { isoLangs } from "../../constants/languages";

import SearchBar from "../../containers/SearchBar";

import { AuthUserContext } from "../Session";

import { withFirebase } from "../Firebase";

import { withAuthorization, withEmailVerification } from "../Session";

import logo from "../../logo-boyanddog.png";

// export const db = app('users');
// console.log("db",db);

class Film extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filmId: props.match.params.filmId,
      error: null,
      isLoaded: false,
      width: 0,
      height: 0,
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  componentWillReceiveProps(nextProps) {
    var url = URL_DETAIL + nextProps.match.params.filmId + API_KEY + API_LANG;
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          var countries = [];
          if (result.production_countries.length > 0) {
            for (var i = 0; i < result.production_countries.length; i++) {
              countries.push(result.production_countries[i].iso_3166_1);
            }
          } else countries.push(result.original_language);

          this.setState({
            isLoaded: true,
            filmId: result.id,
            filmTitle: result.title,
            filmPoster: result.poster_path,
            filmBackdrop: result.backdrop_path,
            filmOverview: result.overview,
            filmTitleOriginal: result.original_title,
            filmPopularity: result.popularity,
            filmReleaseDate: result.release_date,
            filmVoteAverage: result.vote_average,
            filmLanguage: result.original_language,
            filmCountry: countries,
            genres: result.genres,
            tipo: "film",
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.filmId !== prevState.filmId) {
      var url = URL_DETAIL + this.state.filmId + API_KEY + API_LANG;
      fetch(url)
        .then((res) => res.json())
        .then(
          (result) => {
            var countries = [];
            if (result.production_countries.length > 0) {
              for (var i = 0; i < result.production_countries.length; i++) {
                countries.push(result.production_countries[i].iso_3166_1);
              }
            } else countries.push(result.original_language);
            this.setState({
              isLoaded: true,
              filmId: result.id,
              filmTitle: result.title,
              filmPoster: result.poster_path,
              filmBackdrop: result.backdrop_path,
              filmOverview: result.overview,
              filmTitleOriginal: result.original_title,
              filmPopularity: result.popularity,
              filmReleaseDate: result.release_date,
              filmVoteAverage: result.vote_average,
              filmLanguage: result.original_language,
              filmCountry: countries,
              genres: result.genres,
              tipo: "film",
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    }
  }

  componentDidMount() {
    var url = URL_DETAIL + this.state.filmId + API_KEY + API_LANG;
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          var countries = [];
          if (result.production_countries.length > 0) {
            for (var i = 0; i < result.production_countries.length; i++) {
              countries.push(result.production_countries[i].iso_3166_1);
            }
          } else countries.push(result.original_language);

          this.setState({
            isLoaded: true,
            filmId: result.id,
            filmTitle: result.title,
            filmPoster: result.poster_path,
            filmBackdrop: result.backdrop_path,
            filmOverview: result.overview,
            filmTitleOriginal: result.original_title,
            filmPopularity: result.popularity,
            filmReleaseDate: result.release_date,
            filmVoteAverage: result.vote_average,
            filmLanguage: result.original_language,
            filmCountry: countries,
            genres: result.genres,
            tipo: "film",
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );

    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    //const history = createBrowserHistory();
    //const unlisten = history.listen((location, action) => {
    // location is an object like window.location
    //    console.log(action, location.pathname, location.state);
    //    });

    const { error, isLoaded, items, width, height } = this.state;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loading">Loading...</div>;
    } else {
      var paises = [];
      for (var i = 0; i < this.state.filmCountry.length; i++) {
        paises.push(
          <Flag
            basePath="../../img/flags"
            name={this.state.filmCountry[i]}
            format="png"
            pngSize={16}
            shiny={false}
            alt=""
          />
        );
      }

      if (isoLangs[this.state.filmLanguage].nativeName) {
        var language = isoLangs[this.state.filmLanguage].nativeName;
      } else {
        var language = "English";
      }

      var generosList = [];
      for (var i = 0; i < this.state.genres.length; i++) {
        //generosList.push(<p>{this.state.genres[i].name}</p>);
        generosList.push(
          <span className="badge badge-light mr-2" key={i}>
            <Link
              to={"/genrefilms/" + this.state.genres[i].id}
              params={{
                genreId: this.state.genres[i].id,
                genreName: this.state.genres[i].name,
              }}
            >
              {this.state.genres[i].name}{" "}
            </Link>
          </span>
        );
      }

      let imageUrl = this.state.filmBackdrop
        ? URL_IMG + IMG_SIZE_XLARGE + this.state.filmBackdrop
        : logo;

      return (
        <div className="fakeClassParaLlevarNotesContainerAApp">
          <div className="notesHeader">
            <SearchBar searchText={""} />
          </div>
          <div
            className=""
            style={{
              paddingTop: 30,
              paddingLeft: 30,
              paddingRight: 30,
              paddingBottom: 30,
              height: "auto",
              width: width,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundImage: `linear-gradient(
                325deg,
                rgba(0, 36, 51, 1) 0%,
                rgba(98, 218, 255, 0.164) 100%
                ),url("${imageUrl}")`,
            }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-10">
                  <h1 className="mb-2">{this.state.filmTitle}</h1>
                  <div className="float-left">
                    <StarRatingComponent
                      name="rate2"
                      editing={false}
                      starCount={10}
                      value={this.state.filmVoteAverage}
                    />
                  </div>

                  <div className="float-left">
                    <span className="badge badge-warning ml-2 mb-2 mr-2">
                      {this.state.filmVoteAverage}
                    </span>
                  </div>

                  <div className="float-left">
                    <p className="mb-2">{generosList}</p>
                  </div>
                </div>
                <div className="col-2">
                  <div className="text-right">
                    <div>
                      <AuthUserContext.Consumer>
                        {(authUser) =>
                          authUser ? (
                            <div>
                              <LikeMovieButton
                                film={this.state.filmId}
                                user={authUser}
                              />
                            </div>
                          ) : (
                            <div></div>
                          )
                        }
                      </AuthUserContext.Consumer>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-2">
                  <Image
                    className="image"
                    src={URL_IMG + IMG_SIZE_LARGE + this.state.filmPoster}
                    width="100%"
                    responsive="true"
                  />
                  <p className="px-2 py-2 bg-warning text-dark">
                    {this.state.filmTitleOriginal} (
                    <Moment locale="es" parse="YYYY" format="YYYY">
                      {this.state.filmReleaseDate}
                    </Moment>
                    )
                  </p>
                  <p>
                    <strong>Popularidad:</strong> {this.state.filmPopularity}
                  </p>
                  <p>
                    <strong>Idioma original:</strong> {language}
                  </p>
                  <p>
                    <strong>Países de la producción:</strong> {paises}
                  </p>
                </div>
                <div className="col-sm-10">
                  <p>{this.state.filmOverview}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="notesBody" key={this.state.filmId}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12">
                  <Tabs defaultActiveKey="cast" id="uncontrolled-tab-example">
                    <Tab eventKey="cast" title="Reparto">
                      <Reparto
                        id={this.props.match.params.filmId}
                        tipo={this.state.tipo}
                      />
                    </Tab>
                    <Tab eventKey="crew" title="Equipo">
                      <Equipo
                        id={this.props.match.params.filmId}
                        tipo={this.state.tipo}
                      />
                    </Tab>
                    <Tab eventKey="trailer" title="Trailers">
                      <Trailer trailer={this.props.match.params.filmId} />
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const condition = (authUser) => !!authUser;

export default Film;
