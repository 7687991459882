import React from 'react';

import { withFirebase } from '../Firebase';

const SignOutButton = ({ firebase }) => (


  <a href="#" className="nav-link logout" onClick={firebase.doSignOut} ><i className="fa fa-sign-out-alt"></i></a>


  );



export default withFirebase(SignOutButton);
