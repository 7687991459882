import 'regenerator-runtime/runtime'
import React, { createContext, useReducer} from 'react'
import axios from 'axios'

import spotifyReducer from './SpotifyReducer'

const SpotifyContext = createContext();
const AUTH_ENDPOINT = process.env.REACT_APP_AUTH_ENDPOINT;
const SPOTIFY_API = process.env.REACT_APP_SPOTIFY_API;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

export const SpotifyProvider = ({children}) => {
  const initialState = {
    token: '',
    genres: [],
    playlists: [],
    tracks: [],
  }

  const [state, dispatch] = useReducer(spotifyReducer, initialState);
  const getToken = async () => {
    setLoading();

    const data = await axios(`${AUTH_ENDPOINT}`, {
      headers: {
        'Content-Type' : 'application/x-www-form-urlencoded',
        'Authorization' : 'Basic ' + btoa(CLIENT_ID + ':' + CLIENT_SECRET)
      },
      data: 'grant_type=client_credentials',
      method: 'POST'
    });
    if(data) {
      dispatch({
        type: 'GET_TOKEN',
        payload: data.data.access_token,
      });
    } else {
      console.log('Token not available. Please check Spotify Developer Settings.')
    }

  };

  const getGenres = async () => {
    setLoading();

    const genres = await axios(`${SPOTIFY_API}v1/browse/categories?country=ES&offset=0&limit=50`, {
      method: 'GET',
      headers: {
        'Authorization' : 'Bearer ' + state.token
      }
    });

    if(genres) {
      dispatch({
        type: 'GET_GENRES',
        payload: genres.data.categories.items
      });
    }
  }

  const getPlaylists = async (id) => {

    const playlists = await axios(`${SPOTIFY_API}v1/browse/categories/${id}/playlists/`, {
      method: 'GET',
      headers: {
        'Authorization' : 'Bearer ' + state.token
      }
    });

    if(playlists) {
//      console.log("playlists in category",playlists)
      dispatch({
        type: 'GET_PLAYLISTS',
        payload: playlists.data.playlists.items
      });
    }
  }


  const getTracks = async (id) => {

    const tracks = await axios(`${SPOTIFY_API}v1/playlists/${id}/tracks/`, {
      method: 'GET',
      headers: {
        'Authorization' : 'Bearer ' + state.token
      }
    });

    if(tracks) {
      dispatch({
        type: 'GET_TRACKS',
        payload: tracks.data.items
      });
    }
  }

  const getResults = async (searchTerm) => {
    const results = await axios(`${SPOTIFY_API}v1/search?q=${searchTerm}&type=track`, {

      method: 'GET',
      headers: {
        'Authorization' : 'Bearer ' + state.token
      }
    });

    if(results) {
      dispatch({
        type: 'GET_RESULTS',
        payload: results.data.tracks
      });
    }
  }


  const getResultsAlbums = async (searchTerm) => {
    const resultsalbums = await axios(`${SPOTIFY_API}v1/search?q=${searchTerm}&type=album`, {

      method: 'GET',
      headers: {
        'Authorization' : 'Bearer ' + state.token
      }
    });

    if(resultsalbums) {
      dispatch({
        type: 'GET_RESULTS_ALBUMS',
        payload: resultsalbums.data.albums
      });
    }
  }


  const getResultsArtists = async (searchTerm) => {
    const resultsartists = await axios(`${SPOTIFY_API}v1/search?q=${searchTerm}&type=artist`, {

      method: 'GET',
      headers: {
        'Authorization' : 'Bearer ' + state.token
      }
    });

    if(resultsartists) {
      dispatch({
        type: 'GET_RESULTS_ARTISTS',
        payload: resultsartists.data.artists
      });
    }
  }
  


  const getAlbumDetail = async (id) => {

    const albumDetail = await axios(`${SPOTIFY_API}v1/albums/${id}/`, {
      method: 'GET',
      headers: {
        'Authorization' : 'Bearer ' + state.token
      }
    });

    if(albumDetail) {
     console.log("lbumDetail", albumDetail)
      dispatch({
        type: 'GET_ALBUM_DETAIL',
        payload: albumDetail.data
      });
    }
  }


  const getArtistDetail = async (id) => {

    const artistDetail = await axios(`${SPOTIFY_API}v1/artists/${id}/`, {
      method: 'GET',
      headers: {
        'Authorization' : 'Bearer ' + state.token
      }
    });

    if(artistDetail) {
      dispatch({
        type: 'GET_ARTIST_DETAIL',
        payload: artistDetail.data
      });
    }
  }



  const setLoading = () => dispatch({type: 'SET_LOADING'})
  


  return <SpotifyContext.Provider value={{
    token: state.token,
    genres: state.genres,
    playlists: state.playlists,
    tracks: state.tracks,
    results:state.results,
    resultsalbums:state.resultsalbums,
    resultsartists:state.resultsartists,
    albumDetail: state.albumDetail,
    artistDetail: state.artistDetail,
    getToken,
    getGenres,
    getPlaylists,
    getTracks,
    getResults,
    getResultsAlbums,
    getResultsArtists,
    getAlbumDetail,
    getArtistDetail,
  }}>
    {children}
  </SpotifyContext.Provider>
};

export default SpotifyContext;

