import React, { Component } from 'react';
import { Image } from 'react-bootstrap'
import { URL_SEARCH, URL_DETAIL, URL_TVSHOW, URL_PERSON, API_KEY, API_KEY_ALT, API_LANG, URL_IMG, IMG_SIZE_LARGE, IMG_SIZE_SMALL, IMG_SIZE_XSMALL, RELEASE_DATE_DESC, RELEASE_DATE_ASC } from '../../config/config';
import Moment from 'react-moment';


import { Link } from 'react-router-dom';

import '../../index.css';

//import logo from "../../dog-min.png"
import logo from "../../logo-boyanddog.png"


class Equipo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filmId: props.id,
      filmTitle: props.title,
      filmCast: [],
      filmCrew: [],
      tipo: props.tipo,
      error: null,
      isLoaded: false,
    };
  }


  componentDidMount() {
    var URL;
    if (this.props.tipo == 'film') {
      URL = URL_DETAIL;
    }
    else if (this.props.tipo == 'tvshow') {
      URL = URL_TVSHOW;
    }
    var url = URL + this.props.id + "/credits" + API_KEY + API_LANG;
    fetch(url)
      .then(res => res.json())
      .then(
        (result) => {

          this.setState({
            isLoaded: true,
            items2: result.crew
          });

        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );

  }

  render() {


    const { error, isLoaded, items2 } = this.state;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loading">Loading...</div>;
    } else {
      return (
        <div className="container">


          {items2.map((item2, index) => {

            if (item2.profile_path) {
              return (
                <div key={index}>
                  <div className="row p-2 mb-1">
                    <div className="col-4 my-auto ">
                      <Link to={'/person/' + item2.id} params={{ id: item2.id }} >
                        <Image src={URL_IMG + IMG_SIZE_XSMALL + item2.profile_path} thumbnail />
                      </Link>
                    </div>


                    <div className="col-8 my-auto">
                      <div className="row">
                        <div className="col-12 my-auto ">
                          <p><Link to={'/person/' + item2.id} params={{ id: item2.id }} >{item2.name}</Link></p>

                        </div>
                        <div className="col-12 my-auto ">
                          <p>{item2.job}</p>

                        </div>
                      </div>
                    </div>






                  </div>
                </div>);
            }
            else {
              return (
                <div key={index}>
                  <div className="row p-2 mb-1">
                    <div className="col-4 my-auto ">
                      <Link to={'/person/' + item2.id} params={{ id: item2.id }} >
                        <Image src={logo} thumbnail width="53px;" />
                      </Link>
                    </div>


                    <div className="col-8 my-auto">
                      <div className="row">
                        <div className="col-12 my-auto ">
                          <p><Link to={'/person/' + item2.id} params={{ id: item2.id }} >{item2.name}</Link></p>

                        </div>
                        <div className="col-12 my-auto ">
                          <p>{item2.job}</p>

                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              );
            }


          }
          )
          }

        </div>

      );
    }
  }
}

export default Equipo;
