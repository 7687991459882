import React, { Component } from 'react';
import { Image } from 'react-bootstrap'
import { 
  URL_DETAIL, 
  API_KEY, 
  URL_SEARCH, 
  API_KEY_ALT, 
  API_LANG,
  REGION, 
  URL_IMG, 
  IMG_SIZE_LARGE,
  IMG_SIZE_XLARGE, 
  IMG_SIZE_SMALL, 
  RELEASE_DATE_DESC, 
  RELEASE_DATE_ASC } from '../../config/config';
import Moment from 'react-moment';
import {Link, withRouter} from 'react-router-dom';

import Carousel from 'react-bootstrap/Carousel'

import '../../index.css';

import { createBrowserHistory } from 'history';

//import SearchBar_own from '../SearchBar_own/';
import SearchBar from '../../containers/SearchBar';

class NowPlaying extends Component {
  constructor(props) {
    super(props);
    this.state = {
      films: [],
      error: null,
      isLoaded: false,
      query:'marvel',
      items:[]
    }
  }

  componentDidMount() {

    var url = URL_DETAIL+'now_playing'+API_KEY+API_LANG;
    //var url = "https://api.themoviedb.org/3/movie/now_playing?api_key=a155a20f30c6119012f0a8c61891f1a9&language=es&region=es";
              
    fetch(url)
    .then(res =>  res.json())
    .then(
      (result) => {
        this.setState({
          isLoaded: true,
          items: result.results
          });
      },
      (error) => {
        this.setState({
            isLoaded: true,
            error
          });
        }
      )
    }

    render() {

      const { error, isLoaded, items } = this.state;
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div className="loading">Loading...</div>;
      } else {
        return (
          <div className="">
          {/* <h2 className="text-center">Now Playing</h2> */}
              <Carousel >

              { items.slice(0, 10).map(item => (

                <Carousel.Item key={item.id}>
                    <Link className="text-warning" to={'/film/'+item.id} params={{id:item.id}}><img className="d-block w-100" key={item.id} src={URL_IMG+IMG_SIZE_XLARGE+item.backdrop_path} /></Link>
                  <Carousel.Caption>
                    <Link className="text-warning" to={'/film/'+item.id} params={{id:item.id}}>
                    <h2>{item.video} {item.title} (<Moment  locale="es" parse="YYYY" format="YYYY">{item.release_date}</Moment>) {item.video}</h2>
                    </Link>
                  </Carousel.Caption>

                </Carousel.Item>

))}
              </Carousel>

</div>

        );
      }
    }
  }

  export default NowPlaying;
