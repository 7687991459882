import React, { Component } from 'react';
import { compose } from 'recompose';

import { Image } from 'react-bootstrap'
import { 
  URL_SEARCH,
  URL_LIST,
  URL_DETAIL,
  API_KEY, 
  API_KEY_ALT, 
  API_LANG, 
  URL_IMG, 
  IMG_SIZE_LARGE, 
  IMG_SIZE_SMALL, 
  IMG_SIZE_BEST, 
  RELEASE_DATE_DESC, 
  RELEASE_DATE_ASC,
  URL_GENRES, 
  REGION} from '../../config/config';
import Moment from 'react-moment';
import {Link, withRouter} from 'react-router-dom';


import '../../index.css';
import logo from "../../dog-min.png"
import TruncateMarkup from 'react-truncate-markup';

import { createBrowserHistory } from 'history';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';

import PropTypes from 'prop-types';


import  { FirebaseContext } from '../Firebase';

import { withAuthorization, withEmailVerification } from '../Session';

const readMoreEllipsis = (
  <span>
     {' '}...{' '}
  </span>
  );

class MyMovies extends Component {
  constructor(props) {
    super(props);
    

    this.state = {
      films: [],
      error: null,
      isLoaded: false,
      query:'',
      items:[],
      mymovies:[]
    }

    this.getMovies=this.getMovies.bind(this);
    this.getMovie=this.getMovie.bind(this);

  }

    componentDidMount() {
        this.getMovies(this.props.user.uid);
    }

    getMovie = (filmId) => {
        var url = URL_DETAIL+filmId+API_KEY+API_LANG;
        fetch(url)
        .then(res =>  res.json())
        .then(
          (result) => {
            var country;
            if(result.production_countries.length>0) country =  result.production_countries[0].iso_3166_1;
            else country = result.original_language;
            
            var movieArray = [{ 
              isLoaded: true,
              id: result.id,
              title :  result.title , 
              release_date : result.release_date,
              country: country,
              poster_path: result.poster_path }] 

              this.setState({
                mymovies: [ ...this.state.mymovies, ...movieArray ]
              })
            
            return result;
            
          },
          (error) => {
            this.setState({
                isLoaded: true,
                error
              });
            }
          );
    }

    getMovies = ( uid) => { 
        var listadoLikes = [];
        
        this.props.firebase.db.ref('/users/' + uid).once('value')
        .then( (snapshot) => { 
          
          snapshot.forEach(
            (childSnapshot) => { 
              let key = childSnapshot.key;
              let childData = childSnapshot.val();
              if(key==='likes'){
                listadoLikes = Object.keys(childData);
                for (let index = 0; index < listadoLikes.length; index++) {
                  const element = listadoLikes[index];
                  this.getMovie(element);
                }
              }      
          });
         
          
          this.setState({items:listadoLikes});
          

          return listadoLikes;
        });
      
      }


    

    render() {

      const { error, isLoaded, mymovies } = this.state;
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded && 0) {
        return <div className="loading">Loading...</div>;
      } else {     
        return (
          <div className="modoOculto">
            <h2 className="text-center">My Movies</h2>
            <div className="row mb-2" >
              {
                mymovies.slice(0, mymovies.length).map((item, i)=> {
                  var imagen;
                  imagen = (item.poster_path!=null) ? URL_IMG+IMG_SIZE_BEST+item.poster_path : logo;
                  return(
                        <div className="px-2 col-2 mb-2 view overlay"  key={i}>
                          <Link to={'/film/'+item.id} params={{id:item.id}} ><img className="img-fluid"  src={imagen} /></Link>
                        </div>
                )
              }            
            )
            }
            </div>
          </div>
        );
      }
    }
  }


  const condition = authUser => !!authUser;


  export default compose(
    withFirebase,
    withEmailVerification,
    withAuthorization(condition),
  )(MyMovies);
