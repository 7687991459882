import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { AuthUserContext } from '../Session';

import { withFirebase } from '../Firebase';
import { FirebaseContext } from '../Firebase';

import { withAuthorization, withEmailVerification } from '../Session';

class LikeTvshowButton extends Component {

  constructor(props) {

    super(props);
    this.state = {
      tvshowId: props.tvshow,
      error: null,
      like: null
    };

    this.getLikes = this.getLikes.bind(this);

  }

  componentDidMount() {
    this.getLikes(this.props.tvshow, this.props.user);
    //   this.setState({tvshowId: this.state.tvshowId});

  }
  componentDidUpdate() {

  }
  setLike(tvshowId, uid, status) {

    var postData = { like: status };
    var newPostKey = this.props.firebase.db.ref().child('/users/' + uid + '/likestv/').push().key;
    var updates = {};
    updates['/users/' + uid + '/likestv/' + tvshowId] = postData;
    this.props.firebase.db.ref().update(updates);
    this.setState({ tvshowId: tvshowId, like: status });
  }

  snapshotToArray(snapshot) {
    var returnArr = [];

    snapshot.forEach(function (childSnapshot) {
      var item = childSnapshot.val();
      item.key = childSnapshot.key;

      returnArr.push(item);
    });

    return returnArr;
  };


  getLikes = (tvshowId, uid) => {
    var listadoLikes = [];
    console.log("getlikes", tvshowId);
    this.props.firebase.db.ref('/users/' + uid).once('value')
      //.then( function(snapshot) {
      .then((snapshot) => {
        const likes = snapshot.val().likestv;

        snapshot.forEach(
          //function(childSnapshot) {
          (childSnapshot) => {
            let key = childSnapshot.key;
            let childData = childSnapshot.val();
            if (key === 'likestv') {
              listadoLikes = Object.keys(childData);
            }
          });

        if (listadoLikes.indexOf(tvshowId.toString()) > 0) {
          //console.log("like true", this.state);
          //this.state = {like: true };
          this.setState({ tvshowId: tvshowId, like: true });
          // return <i className="far fa-heart text-danger"></i>
        }
        else {
          //console.log("like false", this.state);
          //this.state = {like: false };
          this.setState({ tvshowId: tvshowId, like: false });
          //return <i className="fa fa-heart text-danger"></i>

        }
      });

  }

  render() {

    const { error, tvshowId, like } = this.state;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else {
      return (
        <FirebaseContext.Consumer>
          {
            firebase => {
              return (

                <button onClick={() => { this.setLike(this.props.tvshow, this.props.user.uid, !like) }} color="success" type="button" className="btn btn-outline-secondary" data-toggle="tooltip" data-placement="top" title="Mark as favourite">
                  {
                    (like) ? <div><i className="fa fa-heart text-danger"></i></div> : <div><i className="far fa-heart text-danger"></i></div>
                  }
                </button>


              );
            }
          }
        </FirebaseContext.Consumer>
      );
    }
  }
}

const condition = authUser => !!authUser;


export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(LikeTvshowButton);
