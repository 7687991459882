import React, { Component } from 'react';

import { UNSPLASH_URL, UNSPLASH_APP_ACCESS_KEY } from '../../config/config';

import '../../index.css';
//import placeholder from '../300x200.png'; // relative path to image



class RandomImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageurl: '',
      error: null,
      isLoaded: false

    };


  }


  componentDidMount() {
    var sig = '&sig=' + Math.random();

    var topic = this.props.topic;

    var url =UNSPLASH_URL+topic+UNSPLASH_APP_ACCESS_KEY+sig;

      fetch(url)
      .then(res =>  res.json())
      .then(
        (result) => {
          this.setState( {
              isLoaded: true,
              imageurl: result.urls['thumb']
            }
          );
        },
        (error) => {
          this.setState({
              isLoaded: true,
              imageurl: require('../../300x200.png'),
              error
            }
          );
        }
      );
}




  render() {

    return (
       <div className='imageContainer'>
       <img src={this.state.imageurl} alt="imagen" />
       </div>

    );
  }
}

export default RandomImage;
