import React, { Component, useContext, useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import {
  URL_SEARCH,
  API_KEY_ALT,
  API_LANG,
  CANALES_SELECCIONADOS,
  CANALES_ID_ITSNOTTV,
  URL_IMG,
  IMG_SIZE_LARGE,
  IMG_SIZE_SMALL,
  RELEASE_DATE_DESC,
  RELEASE_DATE_ASC,
} from "../../config/config";
import Moment from "react-moment";
import { Link, withRouter } from "react-router-dom";

import NowPlaying from "../NowPlaying";
import Estrenos from "../Estrenos";
import EstrenosTV from "../EstrenosTV";
import TopPerson from "../TopPerson";
import PopularPeople from "../PopularPeople";
import TopRatedMovies from "../TopRatedMovies";
import TopRatedTVShows from "../TopRatedTVShows";
import TabNetwork from "../TabNetwork";

import MoviesByGenre from "../MoviesByGenre";
import MyMovies from "../MyMovies";

import LogoNetwork from "../LogoNetwork";
import Carousel from "react-bootstrap/Carousel";

import "../../index.css";

import { createBrowserHistory } from "history";

//import SearchBar_own from '../SearchBar_own/';
import SearchBar from "../../containers/SearchBar";
import SearchBarTv from "../../containers/SearchBarTv";
import SearchBarPerson from "../../containers/SearchBarPerson";

import { AuthUserContext } from "../Session";

import {
  TwitterTimelineEmbed,
  TwitterShareButton,
  TwitterFollowButton,
  TwitterHashtagButton,
  TwitterMentionButton,
  TwitterTweetEmbed,
  TwitterMomentShare,
  TwitterDMButton,
  TwitterVideoEmbed,
  TwitterOnAirButton,
} from "react-twitter-embed";

import { Fragment } from "react"; // import Fragment from React
import Select from "react-select";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import TVProgramacion from "../TVProgramacion";
import Toptracks from "../Toptracks";

import SpotifyContext from "../Spotify/SpotifyContext";
import TVProgramacionProgramasAhora from "../TVProgramacionProgramasAhora";
import Toprecords from "../Toprecords";

const options = [
  {
    value: "film",
    label: (
      <div>
        <span>&nbsp;Cine</span>
      </div>
    ),
    title: "Cine",
  },
  {
    value: "tv",
    label: (
      <div>
        <span>&nbsp;Televisión</span>
      </div>
    ),
    title: "Televisión",
  },
  {
    value: "user",
    label: (
      <div>
        <span>&nbsp;Gente</span>
      </div>
    ),
    title: "Gente",
  },
];

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "blue" : "gray",
    height: "35px",
  }),
};

//class Landing extends Component {
function Landing(props) {
  const { getToken, getGenres } = useContext(SpotifyContext);

  const [films, setFilms] = useState("");
  const [error, setError] = useState("");
  const [isLoaded, setIsLoaded] = useState("");
  const [query, setQuery] = useState("");
  const [showSearchBar, setShowSearchBar] = useState("");
  const [showSearchBarTitle, setShowSearchBarTitle] = useState("");

  const onChangeSelect = (event) => {
    setShowSearchBar(event.value);
    setShowSearchBarTitle(event.title);
  };

  return (
    <div className="fakeClassParaLlevarNotesContainerApp">
      <div className="notesHeader">
        <div className="row">
          <div className="col-12">
            <Select
              placeholder={
                <div>
                  {" "}
                  {showSearchBarTitle ? (
                    showSearchBarTitle
                  ) : (
                    <span>¿Qué quieres buscar?</span>
                  )}{" "}
                </div>
              }
              options={options}
              styles={customStyles}
              onChange={onChangeSelect}
              value={showSearchBar}
            />

            {/*} <select onChange={this.onChangeSelect} value={this.state.showSearchBar} data-size="4" className="form-control border " id="selectSearch">
                        <option value=""><FontAwesomeIcon icon={faFilm} /></option>
                        <option value="movies"><FontAwesomeIcon icon={faFilm} />Movies</option>
                        <option value="tvshows">TV Shows</option>
                        <option value="people">People</option>
                      </select>
              */}
          </div>
          <div className="col-12">
            {showSearchBar == "film" ? (
              <div className="{showSearchBar}">
                <SearchBar searchText={""} />
              </div>
            ) : (
              ""
            )}
            {showSearchBar == "tv" ? (
              <div className="{showSearchBar}">
                <SearchBarTv searchText={""} />
              </div>
            ) : (
              ""
            )}
            {showSearchBar == "user" ? (
              <div className="{showSearchBar}">
                <SearchBarPerson searchText={""} />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="notesBody">
        <div className="container">
          <div className=" break-out">
            <div className="">
              <NowPlaying />
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12 ">
              {/*                  <br /> <Estrenos />  */}
              <br /> <EstrenosTV />
              <br />
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <br />
          <div className="row">
            {/*<div className="col-md-2 col-sm-6 col-xs-6 mt-2"><TopRatedMovies /></div>*/}
            {/*<div className="col-md-2 col-sm-6 col-xs-6 mt-2"> <TopRatedTVShows /></div>     */}

            <div className="col-md-3 col-sm-6 col-xs-12 mt-2">
              <h2 className="text-center">Ahora en Televisión</h2>
              <div className="list-group w-100">
                {CANALES_SELECCIONADOS.map((canal, i) => {
                  return (
                    <TVProgramacionProgramasAhora
                      idcanal={CANALES_ID_ITSNOTTV[i]}
                      channels={canal}
                    />
                  );
                })}
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12 mt-2">
              <Toptracks />
            </div>

            <div className="col-md-3 col-sm-6 col-xs-12 mt-2">
              <TopRatedMovies />
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12 mt-2">
              <TopRatedTVShows />
            </div>

            {/*                  
                  <div className="col-md-2 col-sm-6 col-xs-6 mt-2">
               <TwitterTimelineEmbed
                    sourceType="profile"
                    theme="dark"
                    noHeader
                    noFooter
                    transparent
                    linkColor="#4183c4"
                    screenName="Pumares30"
                    options={{height: 800}}
                    />

                  </div>
                  */}
          </div>

          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-6 mt-2"></div>
            <div className="col-md-6 col-sm-6 col-xs-6 mt-2"></div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-3 col-xs-3"></div>
            <div className="col-md-3 col-sm-3 col-xs-3"></div>
            <div className="col-md-3 col-sm-3 col-xs-3"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
