import React from 'react';
import { Link } from 'react-router-dom';

import { AuthUserContext } from '../Session';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

//import { Container, Menu } from 'semantic-ui-react';

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button';

// import logo from "../../logo-min.png"
import logo from "../../logo-boyanddog.png"
import '../../index.css';
import SearchBar from '../../containers/SearchBar';



const Navigation = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <NavigationAuth authUser={authUser} />
      ) : (
        <NavigationNonAuth />
      )
    }
  </AuthUserContext.Consumer>
);

const NavigationAuth = ({ authUser }) => (

  <Navbar bg="dark" variant="dark" expand="lg" sticky="top" >
     <Navbar.Brand href="/">
      <img width="auto" height="55" className="d-inline-block align-top" alt="" src={logo} />
      <span className="logo-texto">el perro rabioso</span>

    </Navbar.Brand>


    
       <Navbar.Toggle aria-controls="basic-navbar-nav"  className="float-right" />



    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mr-auto">
        <Nav.Link active={location.pathname.includes('film')? 'active':''} href="/films">Cine</Nav.Link>
        <Nav.Link active={location.pathname.includes('tvshow')? 'active':''}  href="/tvshows">Series y TV</Nav.Link>
        <Nav.Link active={location.pathname.includes('music')? 'active':''} href="/music">Música</Nav.Link>
        <Nav.Link href="#">Podcasts y Radio</Nav.Link>
        <Nav.Link href="#">Libros</Nav.Link>
        

        {/* { authUser.roles.includes(ROLES.ADMIN) && ( <Nav.Link href={ROUTES.HOME}>Inicio</Nav.Link> )} */}
        {/* { authUser.roles.includes(ROLES.ADMIN) && ( <Nav.Link href={ROUTES.LANDING}>Mensajes</Nav.Link> )} */}
        </Nav>
        <Nav>
          { authUser.roles.includes(ROLES.ADMIN) && ( 
              <Nav.Link href={ROUTES.ACCOUNT} className="account">
                <img aria-controls="admin-navbar-nav" width='32' className='mr-auto rounded-circle responsive-img ' src={authUser.providerData[0].photoURL} />        
                <span className="ml-2 nombre-usuario-logado">{authUser.providerData[0].displayName}</span>
                
              </Nav.Link> )}
          
          
          { authUser.roles.includes(ROLES.ADMIN) && ( <Nav.Link className="admin" href={ROUTES.ADMIN}><i className="fa fa-user-cog"></i></Nav.Link> )}
          { authUser && ( <SignOutButton  />  )}
          
      </Nav>
      

   

    </Navbar.Collapse>


    

  </Navbar>

);

const NavigationNonAuth = ({ authUser }) => (
  <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
    
    <Navbar.Brand href="/">
      <img  width="auto" height="55" className="d-inline-block align-top logo-imagen"  alt="" src={logo} />
      <span className="logo-texto">el perro rabioso</span>

    </Navbar.Brand>
    
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mr-auto">
        {/* <Nav.Link href="/"><i className="fa fa-dog"></i>&nbsp;Inicio</Nav.Link> */}
        <Nav.Link active={window.location.pathname.includes('/film')? 'active':''} href="/films">Cine</Nav.Link>
        <Nav.Link active={window.location.pathname.includes('/tvshow')? 'active':''}  href="/tvshows">Series y TV</Nav.Link>
        <Nav.Link active={window.location.pathname.includes('/music')? 'active':''} href="/music">Música</Nav.Link>
        
      </Nav>
      

      <Nav >
      <Nav.Link href="/signin" className="login-link"><i className="fa fa-user-circle"></i></Nav.Link>
      </Nav>


    </Navbar.Collapse>

  </Navbar>
);

export default Navigation;
