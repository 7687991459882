import React, { Component } from 'react';
import { Image } from 'react-bootstrap'
import { 
  URL_SEARCH, 
  API_KEY_ALT, 
  API_LANG,  
  URL_LIST,
  URL_LIST_TV,
  URL_LIST_TV_ONTHEAIR,
  API_KEY, 
  REGION, 
  URL_IMG, 
  IMG_SIZE_LARGE, 
  IMG_SIZE_SMALL, 
  IMG_SIZE_BEST, 
  RELEASE_DATE_DESC, 
  RELEASE_DATE_ASC } from '../../config/config';import Moment from 'react-moment';
import {Link, withRouter} from 'react-router-dom';

import Carousel from 'react-bootstrap/Carousel'

import '../../index.css';

import HorizontalScrollTV from '../HorizontalScrollTV';


import { createBrowserHistory } from 'history';

//import SearchBar_own from '../SearchBar_own/';
import SearchBar from '../../containers/SearchBar';

class TVProgramacionProgramas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      films: [],
      channels: props.channels,
      error: null,
      isLoaded: false,
      query:''
    }    
  }

  componentDidMount() {    
    
    let newDate = new Date()
    let day = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    var hours = newDate.getHours();
    var minutes = newDate.getMinutes();    

    if(day<10) day="0"+day; 
    if(month<10) month="0"+month; 
      
    // caracter para varias networks |
    // var url = URL_LIST_TV+'?air_date.gte='+year+'-'+month+'-'+day+'&air_date.lte='+year2+'-'+month2+'-'+day2+API_KEY_ALT+API_LANG+REGION+'&language=es-ES&sort_by=popularity.desc&timezone=Europe%2FMadrid&with_networks='+networks[0]['id'];
    var url_programacion = "https://grids.its-not.tv/api/getTVgrid?day="+year+month+day+"&channels="+this.state.channels+"&country=ES";
    fetch(url_programacion)
    .then(res =>  res.json())
    .then(
      (result) => {
        this.setState({
          isLoaded: true,
          items: result.channels
          });
      },
      (error) => {
        this.setState({
            isLoaded: true,
            error
          });
        }
      )
    }

    render() {
      
      let newDate = new Date()
      let hours = newDate.getHours();
      let minutes = newDate.getMinutes();    

      const { error, isLoaded, items } = this.state;
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div className="loading">Loading...</div>;
      } else {
        if(items){
          var programas = Object.values(items);

          const ahora  = new Date();
          //console.log("AHORA ",ahora.getTime());
          
          if(Array.isArray(programas[0]) ){
            return (
              <div className="row  ">
            <div className="list-group w-100">
                  
                  
                    {programas[0].map((value, index) => {
                      let inicioprograma = new Date(value.start);
                      let finprograma = new Date(value.stop);
                      
                      
                  
                      //if(finprograma.getTime() > ahora.getTime()){
                      if(true){
                      return( 




                    <div className="list-group-item list-group-item-action flex-column align-items-start" key={value.id}>
                    
                    <div className='d-flex align-items-top'>
                    
                    <div className='emitiendo'>
                        { (finprograma.getTime() > ahora.getTime() && inicioprograma.getTime()<ahora.getTime()  )? (
                          <span className="dot dot--basic" aria-hidden="true"></span>
                          ):''}
                          
                          </div>

                        <div className="mr-3  ">
                          <Moment key={value.id} locale="es" format="HH:mm">{value.start}</Moment>
                        </div>
                        <div className="ms-3">
                          <p className="fw-bold mb-1">{value.title} </p>
                          <p className="text-muted mb-0"><span className="badge badge-dark ">{value.type}</span>
                          </p>
                          
                        </div>
                       
                        

                    </div>

                    
                  </div>

                          
                          )

                      }
                      else{
                        return;
                      }
                      
                    })
                    }       
                 



                  



                  </div>
                  </div>




                    




              

          );
          }
          return(<div></div>);
          
        }
        else{
          return(<div>No hay programación para hoy</div>);
        }
      }
    }
  }

  export default TVProgramacionProgramas;
