import React, { Component } from 'react';
import { Image } from 'react-bootstrap'
import { URL_SEARCH, API_KEY_ALT, API_LANG, URL_IMG, IMG_SIZE_LARGE, IMG_SIZE_SMALL, IMG_SIZE_BEST, RELEASE_DATE_DESC, RELEASE_DATE_ASC } from '../../config/config';
import Moment from 'react-moment';
import {Link, withRouter} from 'react-router-dom';

import Carousel from 'react-bootstrap/Carousel'

import '../../index.css';

import logo from "../../dog-min.png"


import { createBrowserHistory } from 'history';

//import SearchBar_own from '../SearchBar_own/';
import SearchBar from '../../containers/SearchBar';

class TopPerson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      films: [],
      error: null,
      isLoaded: false,
      query:''
    }
  }

  componentDidMount() {
    var url = "https://api.themoviedb.org/3/trending/person/day?api_key=a155a20f30c6119012f0a8c61891f1a9&language=es&region=es";

    fetch(url)
    .then(res =>  res.json())
    .then(
      (result) => {
        this.setState({
          isLoaded: true,
          items: result.results
          });
      },
      (error) => {
        this.setState({
            isLoaded: true,
            error
          });
        }
      )
    }

    render() {


      const { error, isLoaded, items } = this.state;
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div className="loading">Loading...</div>;
      } else {
        return (
          <div className="modoOculto">
          <h2 className="text-center">Trending People</h2>



              {items.slice(0, 5).map((item, i)=> {
                var imagen;
                imagen = (item.profile_path!=null) ? URL_IMG+IMG_SIZE_BEST+item.profile_path : logo;
                return(

                  <div className="row mb-2" key={item.id}>

                    <div className="col-3 ">
                    <Link to={'/person/'+item.id} params={{id:item.id}} >
                      <img className="float-left  img-thumbnail"  src={imagen} />
                    </Link>
                    </div>
                    <div className="col-9 ">
                      <div><Link to={'/person/'+item.id} params={{id:item.id}} >{item.name}</Link></div>
                      <div><em>{item.known_for_department}</em></div>

                    </div>

                  </div>


                )
              }
              )}
        </div>

        );
      }
    }
  }

  export default TopPerson;
