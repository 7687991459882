import React, { Component } from 'react';
import { Image } from 'react-bootstrap'
import { URL_SEARCH, URL_DETAIL, URL_TVSHOW, URL_PERSON,API_KEY,API_KEY_ALT, API_LANG, URL_IMG, IMG_SIZE_LARGE, IMG_SIZE_SMALL, IMG_SIZE_XSMALL, RELEASE_DATE_DESC, RELEASE_DATE_ASC } from '../../config/config';
import Moment from 'react-moment';


import {Link} from 'react-router-dom';

import '../../index.css';

import logo from "../../dog-min.png"

class TvshowSeasons extends Component {
  constructor(props) {
    // console.log("constructor reparto");

    super(props);
    this.state = {
      filmId: props.id,
      filmTitle: props.title,
      filmCast:[],
      filmCrew:[],
      tipo: props.tipo,
      episodes: null,
      id:null,
      error: null,
      isLoaded: false,
    };



  }


  componentDidMount() {
    // console.log("componentDidMount reparto");

    var URL;
    if(this.props.tipo=='film'){
      URL = URL_DETAIL;
    }
    else if(this.props.tipo=='tvshow'){
      URL =  URL_TVSHOW;
    }
    


      var url = URL+this.props.id+"/season/"+this.props.seasons+API_KEY+API_LANG;

      fetch(url)
      .then(res =>  res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            episodes: result.episodes,
            id:result.id,
            season_name: result.name,
            poster_path: result.poster_path,
            season_number: result.season_number,
            air_date: result.air_date,
            overview: result.overview
            
          });
        },
        (error) => {
          this.setState({
              isLoaded: true,
              error
            });
          }
        );





  }

  render() {

    const { error, isLoaded, episodes } = this.state;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loading">Loading...</div>;
    } else {

      let idHeading="heading-"+this.props.id+"-"+this.props.seasons;
      let dataTarget="#collapse"+this.props.id+"-"+this.props.seasons;
      let dataTargetId="collapse"+this.props.id+"-"+this.props.seasons;
      
      return (
          <div className="">
          
          
          <div className="">

          <div className="card w-100" >
            <div className="card-header" id={idHeading}>
              
              <div className="float-left w-15">
                <Image className="img-thumbnail  " src={URL_IMG+IMG_SIZE_SMALL+this.state.poster_path} width="" responsive="true" />
                
              </div>
              <div className="float-left w-85 h-auto">

                <h2 className="my-auto pl-2 ">
                  
                  <button className="btn btn-outline-light btn-lg btn-block" type="button" data-toggle="collapse" data-target={dataTarget} aria-expanded="true" aria-controls={dataTarget}>
                  
                    <p>{this.state.season_name} </p>
                    
                  </button>
                   
                </h2>
                <span className="mt-2 float-left pl-2">Episodios: {episodes && episodes.length }</span>
                <span className="mt-2 float-right"><i className="fa fa-calendar-alt" aria-hidden="true"></i>&nbsp;<em><Moment locale="es" parse="YYYY-MM-DD" format="DD/MM/YYYY">{this.state.air_date}</Moment> </em></span>
                <br />
                <p className="pl-2 mt-2 ">Detalles {this.state.id}</p>
              </div>
              
            </div>

            <div id={dataTargetId} className="collapse " aria-labelledby={idHeading} data-parent="#accordionExample">
              <div className="card-body">
              <p className="text-justify">{this.state.overview}</p>
                
              <h4>Episodios</h4>
              <table className="table table-sm">
                
                <tbody>
         

                  
              {episodes && episodes.map((item,index) => {
                let season_number=item.season_number.toString().padStart(2, '0');
                let episode_number=item.episode_number.toString().padStart(2, '0');
                if(item.id){
                  let modal="modal"+item.id;
                  let modalTarget="#modal"+item.id;
                  return(
                    <tr key={index}>
                      <td>
                      <span>s{season_number}e{episode_number} - {item.name}</span>
                      <button type="button" 
                        className="btn btn-outline-light float-right" 
                        data-toggle="modal" 
                          data-target={modalTarget} 
                          aria-expanded="true">
                        <i className="fa fa-eye"></i>
                      </button>
                      <div key={index} className="modal fade text-dark" id={modal} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">
                              s{season_number}e{episode_number} - {item.name} ({item.episode_number}/{episodes.length})
                              </h5>
                              
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-6 pl-2">
                                  <Image className="img-thumbnail w-100  " src={URL_IMG+IMG_SIZE_SMALL+item.still_path} width="" responsive="true" /> 
                                  <span className="float-right"><em><Moment locale="es" parse="YYYY-MM-DD" format="DD/MM/YYYY">{item.air_date}</Moment></em></span>
                                  <span><i className="fa fa-star text-warning"></i> {item.vote_average}</span>
                                </div>
                                <div className="col-6 pl-0 pr-4">
                                  <p className="text-justify">{item.overview}</p>
                                </div>
                                  
                                
                              </div>
                              
                            </div>
                            <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                            </div>
                          </div>
                        </div>
                      </div>
                

                        

                        
                        
                      </td>
                      
                    </tr>
                  
                  );
                  }
                }
                )
              }

                </tbody>
              </table>


              
              </div>
            </div>
          </div>
          
            
          </div>




          </div>

          );
        }
      }
    }

export default TvshowSeasons;
