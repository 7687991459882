import React, { Component } from 'react';
import './Note.css';

//import boyanddog from '../boyanddog.png'; // relative path to image

import { confirmAlert } from 'react-confirm-alert'; // Import



import RandomImage from '../RandomImage/index';

class Note extends Component {

	constructor(props) {
		super(props);
		this.noteContent = props.noteContent;
		this.noteId = props.noteId;
		this.createdby = props.createdby;
		this.likes_count = props.likes_count;
		this.indice = props.indice;

		this.state = {
			likes_count : props.likes_count,
      clicks: 0
    };

	}

	componentDidUpdate(prevProps, prevState, snapshot) {
//console.log(prevState);
		if (this.state.likes_count !== prevState.likes_count) {
  //    console.log('updated');
		}
    console.groupEnd()
  }

	componentDidMount() {
    //console.log('componentDidMount');
  }


	setUserLike = (id,nombre) => {
		console.log("nombre ",nombre);
		var userLikesToUpdate = db.child(id);
		var postData = {otro: true, otra: true}

		var updates = {};

  	updates['likes'] = postData;
		userLikesToUpdate.update(updates);



	}

	IncrementItem = (id) => {
//		console.log(this);
		this.setState({ likes_count: this.state.likes_count + 1 });
		var likesToUpdate = db.child(id).child("likes_count");
		likesToUpdate.transaction(function(currentLikes) {
			console.log("currentlikes +:",currentLikes);
			return currentLikes + 1;
		});

		likesToUpdate.on("value", function(snapshot) {
		   console.log("snapshot +: ",snapshot.val());
			 console.log(snapshot.val());


 		}, function (error) {
		   console.log("Error: " + error.code);
		});
	}

	DecreaseItem = (id) => {
		this.setState({ likes_count: this.state.likes_count - 1 });
		var likesToUpdate = db.child(id).child("likes_count");
		likesToUpdate.transaction(function(currentLikes) {
			console.log("currentlikes -",currentLikes);

		   return currentLikes - 1;
		});

		//likesToUpdate.on("value", function(snapshot) {
		//	 console.log("snapshot -: ",snapshot.val());
		//	}, function (error) {
		//		 console.log("Error: " + error.code);
		//	});

	}

	ToggleClick = () => {
		this.setState({ show: !this.state.show });
	}

	handleRemoveNote(id) {
		this.props.removeNote(id);
	}


	submit = (id) => {
	confirmAlert({
		title: 'Confirm to submit',
		message: 'Are you sure to do this?',
		buttons: [
			{
				label: 'Yes',
				onClick: () => this.handleRemoveNote(id)
			},
			{
				label: 'No',
				onClick: () => alert('Click No')
			}
		]
		})
	};

	render(props) {

		let boxClass = ["box"];
    if(this.state.addClass) {
      boxClass.push('green');
    }

		return (
			<div className="col-md-3 col-sm-12 Note">

				<div className="card">
				<div className="card-header">
			    <span className="mt-4"><strong>{this.createdby}</strong></span>
			    <span
						className="btn btn-light btn-close btn-sm float-right"
						onClick={() => this.submit(this.noteId)}
					>
					<i className="fa fa-trash"></i>
					</span>

			  </div>
				<div className="card-body">
				<RandomImage />
				<p className="card-text"><span className="badge badge-pill badge-dark">{this.state.likes_count} likes</span></p>
				<p className="card-text"><strong>{this.createdby}</strong> {this.noteContent}</p>


					<span className="float-left ml-4 mb-2 btn btn-light">
							<i className="fa fa-thumbs-up" onClick={() => { this.IncrementItem(this.noteId); this.setUserLike(this.noteId, "juan") } }></i>
					</span>
					<span className="float-right mr-4 mb-2 btn btn-light">
							<i className="fa fa-thumbs-down" onClick={() => this.DecreaseItem(this.noteId)}></i>
					</span>

				</div>




				</div>
			</div>
		)
	}

}



export default Note;
