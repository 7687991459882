import MovieContainer from './MovieContainer';
import MovieDetail from './MovieDetail';
import SearchBar from './SearchBar';
import SearchBarTv from './SearchBarTv';
import SearchBarPerson from './SearchBarPerson';
import StarDetail from './StarDetail';



export {MovieContainer, MovieDetail, SearchBar,SearchBarTv,SearchBarPerson, StarDetail};
