import React, { Component, useState } from "react";
import { Image } from "react-bootstrap";
import {
  URL_SEARCH,
  API_KEY_ALT,
  API_LANG,
  URL_IMG,
  IMG_SIZE_LARGE,
  IMG_SIZE_SMALL,
  IMG_SIZE_BEST,
  RELEASE_DATE_DESC,
  RELEASE_DATE_ASC,
} from "../../config/config";
import Moment from "react-moment";
import { Link, withRouter } from "react-router-dom";


import "../../index.css";

import { createBrowserHistory } from "history";

import Toptracks from "../Toptracks";
import Toprecords from "../Toprecords";

import SpotifySearch from "../BuscadorSpotify";
import SpotifySearchAlbums from "../BuscadorSpotifyAlbums";

import Select from 'react-select';
import SpotifySearchArtists from "../BuscadorSpotifyArtists";

//class Music extends Component {
//  constructor(props) {
 //   super(props);
//    this.state = {
 //     error: null,
 //     isLoaded: true,
  //  };
  //}



  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "blue" : "gray",
      height: "35px",
    }),
  };
  


  function Music(props){

   
    const [showSearchBar, setShowSearchBar] = useState("");
    const [showSearchBarTitle, setShowSearchBarTitle] = useState("");
  
    const onChangeSelect = (event) => {
      setShowSearchBar(event.value);
      setShowSearchBarTitle(event.title);
    };



const options = [
  {
    value: "albums",
    label: (
      <div>
        <span>&nbsp;Albums</span>
      </div>
    ),
    title: "Albums",
  },
  {
    value: "tracks",
    label: (
      <div>
        <span>&nbsp;Tracks</span>
      </div>
    ),
    title: "Tracks",
  },
  {
    value: "artists",
    label: (
      <div>
        <span>&nbsp;Intérprete</span>
      </div>
    ),
    title: "Intérprete",
  },
];



const [error, setError] = useState("");
  const [isLoaded, setIsLoaded] = useState("");
  

  return (
  

   
        <div className="">
          <div className="notesHeader">
            
          


          <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12 mt-2">
                <div className="">

                <Select
                  placeholder={
                    <div>
                      {" "}
                      {showSearchBarTitle ? (
                        showSearchBarTitle
                      ) : (
                        <span>¿Qué quieres buscar?</span>
                      )}{" "}
                    </div>
                  }
                  options={options}
                  styles={customStyles}
                  onChange={onChangeSelect}
                  value={showSearchBar}
                />
                
                </div>
              </div>
            
            <div className="col-12">
            {showSearchBar == "albums" ? (
              <div className="{showSearchBar}">
                <SpotifySearchAlbums />

              </div>
            ) : (
              ""
            )}
            {showSearchBar == "tracks" ? (
              <div className="{showSearchBar}">
                <SpotifySearch />  

              </div>
            ) : (
              ""
            )}
            {showSearchBar == "artists" ? (
              <div className="{showSearchBar}">
                <SpotifySearchArtists />
              </div>
            ) : (
              ""
            )}
          </div>

</div>
            <div className="row">


     

              <div className="col-md-6 col-sm-6 col-xs-12 mt-2">
                <div className="container-fluid">
                  <Toptracks />                  
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-6 mt-2">
                <div className="container-fluid">
                  <Toprecords />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    
  
}

export default Music;
