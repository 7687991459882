import React, { Component } from "react";
import { Image } from "react-bootstrap";
import {
  URL_SEARCH,
  API_KEY_ALT,
  API_LANG,
  URL_IMG,
  IMG_SIZE_LARGE,
  IMG_SIZE_SMALL,
  IMG_SIZE_BEST,
  RELEASE_DATE_DESC,
  RELEASE_DATE_ASC,
} from "../../config/config";
import Moment from "react-moment";
import { Link, withRouter } from "react-router-dom";

import TopRatedMovies from "../TopRatedMovies";

import "../../index.css";

import { createBrowserHistory } from "history";

//import SearchBar_own from '../SearchBar_own/';
import SearchBar from "../../containers/SearchBar";
import MoviesByGenre from "../MoviesByGenre";
import Estrenos from "../Estrenos";

class Films extends Component {
  constructor(props) {
    super(props);
    this.state = {
      films: [],
      error: null,
      isLoaded: false,
      query: "marvel",
    };
  }
  componentDidMount() {
    var url =
      "https://api.themoviedb.org/3/trending/movie/week?api_key=a155a20f30c6119012f0a8c61891f1a9&language=es&sort_by=popularity.desc";
    var cadena = this.props.match.params.keyword;
    //  var url = URL_SEARCH+cadena+API_KEY_ALT+API_LANG;

    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.results,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    // const history = createBrowserHistory();
    //  onst unlisten = history.listen((location, action) => {
    // location is an object like window.location
    // console.log(action, location.pathname, location.state);
    //    });

    const { error, isLoaded, items } = this.state;
    console.log(items);

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loading">Loading...</div>;
    } else {
      return (
        <div className="fakeClassParaLlevarNotesContainerAApp">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12 mt-2">
                <div className="notes-header">
                  <SearchBar searchText={""} />
                  <br /> <Estrenos />
                  <br />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-6 col-xs-12 mt-2">
                <div className="container-fluid">
                  <h2 className="text-center">Tendencias Cine</h2>

                  {items.map((item) => (
                    <div className="row">
                      <div
                        key={item.id}
                        className="col-md-12 col-sm-12 col-xs-12 mb-4"
                      >
                        <div className="row">
                          <div className="col-md-2 col-sm-4 col-xs-2">
                            <Link
                              key={item.id}
                              to={"/film/" + item.id}
                              params={{ id: item.id }}
                            >
                              <Image
                                className="image w-100"
                                key={item.id}
                                src={URL_IMG + IMG_SIZE_BEST + item.poster_path}
                              />
                            </Link>
                          </div>
                          <div className="col-md-10 col-sm-8 col-xs-10">
                            <h3 key={item.id}>
                              <Link
                                className="link-warning"
                                key={item.id}
                                to={"/film/" + item.id}
                                params={{ id: item.id }}
                              >
                                {item.title} (
                                <Moment
                                  key={item.id}
                                  locale="es"
                                  parse="YYYY"
                                  format="YYYY"
                                >
                                  {item.release_date}
                                </Moment>
                                )
                              </Link>
                            </h3>
                            <p>{item.overview}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-6 mt-2">
                <MoviesByGenre genre={18} />
                <TopRatedMovies />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Films;
