import React, { Component, useState , useEffect  } from 'react';
//import { BrowserRouter, Route, Link, Switch } from 'react-router-dom';
//import { IndexRoute } from 'react-router';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button';
import Navigation from './components/Navigation';
import Routes from './Routes';
import { AuthUserContext } from './components/Session';
import { withAuthentication } from './components/Session';
import SignOutButton from './components/SignOut';
import * as ROUTES from './constants/routes';
import * as ROLES from './constants/roles';
import { connect } from 'react-redux'
import { Provider } from "react-redux";
import { Router, Route, IndexRoute, Link, Switch } from 'react-router-dom'
import { syncHistoryWithStore, routerReducer } from 'react-router-redux'
import { ConnectedRouter } from "connected-react-router";
import { combineReducers, applyMiddleware, createStore } from 'redux';
import { createBrowserHistory } from 'history'
import thunk from 'redux-thunk';
import reducer from './reducers';
import style from './styles/main.scss';

import { SpotifyProvider } from './components/Spotify/SpotifyContext';

const App = () => {

	const store = createStore(reducer, applyMiddleware(thunk));
	const history = syncHistoryWithStore(createBrowserHistory(), store);


	
	return(


			<Provider store={store}>

				<div className="default">

					<Navigation />				
					<SpotifyProvider>
					<Router history={history} children={Routes} />
					{/* <Button
						className="buttonLightNight"
						onClick={this.switch}
						color="success"
						variant="outline-secondary"
					>
						<i className={this.state.classBulb}></i>
					</Button> */}
					  </SpotifyProvider>

						
				</div>
			</Provider>

	)
	

}

export default withAuthentication(App);
