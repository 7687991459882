export const LANDING = '/home';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';

export const FILMS = 'films';
export const LISTADO = 'list';

export const PRIVACIDAD = '/privacidad';

export const CHARTS = 'charts';
