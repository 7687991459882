import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { AuthUserContext } from '../Session';

import { withFirebase } from '../Firebase';
import  { FirebaseContext } from '../Firebase';

import { withAuthorization, withEmailVerification } from '../Session';

import LikeTvshowButton from '../LikeTvshowButton';
import Moment from 'react-moment';

 class ModalTvShow extends Component {

   constructor(props) {

     super(props);
     this.state = {
       tvshowId: props.tvshow,
       error: null,
       comentarios:null,
       like: null,
       newcomment:false
     };

     this.getComments=this.getComments.bind(this);

   }

componentDidMount() {
  this.getComments(this.props.tvshow);
}

componentDidUpdate(){
  

}

getComments = (tvshowId) => { 
  var listadoComments = [];
  this.props.firebase.db.ref('/tvshows/' + tvshowId).once('value')
  //.then( function(snapshot) {
  .then( (snapshot) => { 
    const comments = snapshot.val().comments;
   
    snapshot.forEach(
      //function(childSnapshot) {
      (childSnapshot) => { 
        let key = childSnapshot.key;
        let childData = childSnapshot.val();
        let datos = Object.values(childData);
        this.setState({comentarios: datos});
    });
  });
}

handleSubmit(usuarioId, usuario) {
  console.log("usuario",usuario);
  var comments = this.refs.comment;
  var uid = usuarioId;
  var timestamp = new Date().getTime();
  
  var postData = { comment : this.refs.comment.value, displayName: usuario.displayName, created:timestamp };
  //var newPostKey = this.props.firebase.db.ref().child('/tvshows/' + this.state.tvshowId + '/comments/'+uid).push().key;
  var updates = {};
  updates['/tvshows/' + this.state.tvshowId+'/comments/'+timestamp+'/'+uid] = postData;
  this.props.firebase.db.ref().update(updates);
  
  comments.value = "";

  this.getComments(this.props.tvshow);
  this.setState({newcomment:true});



}

  snapshotToArray(snapshot) {
    var returnArr = [];

    snapshot.forEach(function(childSnapshot) {
        var item = childSnapshot.val();
        item.key = childSnapshot.key;

        returnArr.push(item);
    });

    return returnArr;
  };



  render() {

    const { error, tvshowId, like, comentarios} = this.state;

    
    if (error) {
      return <div>Error: {error.message}</div>;
    }  else {

      if(true){
      return( 
      <div className="modal fade  text-dark" data-backdrop="false" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
            <div className="modal-dialog modal-fluid" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="modalLabel">{this.props.tvshowname}</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                

                <form onSubmit={this.handleSubmit}>   
                  <div className="modal-body">
                  <p>Comments</p>
                    { this.state.comentarios!=null && 
                      this.state.comentarios.map((item,index) => {
                        let com = Object.values(item);
                        let usu = Object.keys(item);
                        return(<p key={index}><span><Moment locale="es" parse="x" format="DD/MM/YYYY hh:mm">{com[0].created}</Moment></span> <strong>{com[0].displayName}</strong>: {com[0].comment}</p>);
                      } 
                      )
                    
                    }
                      <div className="form-group">
                        <label htmlFor="comment"> Add your comment about <em><strong>{this.props.tvshowname}</strong></em></label>
                        <textarea className="form-control" id="comment" rows="3" ref="comment"></textarea>
                      </div>
                    


                  </div>
                  <div className="modal-footer">
                  
                    <div>
                      
                            <span>
                              <LikeTvshowButton tvshow={this.state.tvshowId} user={this.props.user.uid} />
                              <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={ () => {this.handleSubmit(this.props.user.uid, this.props.user) } }>Send</button>
                            </span>
                         
                      
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                  
                  </div>
                  </form>
              </div>
            </div>
          </div>
    );
    }
    else{ return(<p></p>); }
                  
  }
 }
}

const condition = authUser => !!authUser;


export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(ModalTvShow);
