import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

class UserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.users().on('value', snapshot => {
      const usersObject = snapshot.val();

      const usersList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      }));

      this.setState({
        users: usersList,
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  render() {
    const { users, loading } = this.state;

    return (
      <div>
        <h2>Users</h2>
        {loading && <div>Loading ...</div>}
        <table className="table">
        <thead>
          <tr>
          <th scope="col">Avatar</th>
          <th scope="col">#ID</th>
          <th scope="col">E-Mail</th>
            <th scope="col">Username</th>
            <th scope="col">Display Name</th>
            <th scope="col">Actions</th>
          </tr>
          </thead>
          <tbody>
          {users.map(user => (
            <tr key={user.uid}>
              <th scope="row">
                <img width='20' className='mr-auto rounded-circle responsive-img ' src={user.photo} />
              </th>
              <td>
                {user.uid}
              </td>
              <td>
                {user.email}
              </td>
              <td>
                {user.username}
              </td>
              <td>
                {user.displayName}
              </td>
              <td>
                <Link
                  to={{
                    pathname: `${ROUTES.ADMIN}/${user.uid}`,
                    state: { user },
                  }}
                >
                  <i className="fa fa-info-circle"></i>
                </Link>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default withFirebase(UserList);
