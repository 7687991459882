import React, { Component } from 'react';
import { Image } from 'react-bootstrap'
import { 
  URL_SEARCH, 
  API_KEY_ALT, 
  API_LANG,  
  URL_LIST,
  URL_LIST_TV,
  URL_LIST_TV_ONTHEAIR,
  API_KEY, 
  REGION, 
  URL_IMG, 
  IMG_SIZE_LARGE, 
  IMG_SIZE_SMALL, 
  IMG_SIZE_BEST, 
  RELEASE_DATE_DESC, 
  CANALES_SELECCIONADOS,
  RELEASE_DATE_ASC } from '../../config/config';import Moment from 'react-moment';
import {Link, withRouter} from 'react-router-dom';

import Carousel from 'react-bootstrap/Carousel'

import '../../index.css';

import HorizontalScrollTV from '../HorizontalScrollTV';


import { createBrowserHistory } from 'history';

//import SearchBar_own from '../SearchBar_own/';
import SearchBar from '../../containers/SearchBar';

import TVProgramacionProgramas from '../TVProgramacionProgramas';

class TVProgramacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      films: [],
      error: null,
      isLoaded: false,
      query:''
    }
  }

  componentDidMount() {

    // let networks;
    // networks[0]['id']=2140; networks[0]['nombre']='#0';
    // networks[1]['id']=443; networks[2]['nombre']='La 1';
    // networks[2]['id']=1346; networks[2]['nombre']='La 2';
    // networks[3]['id']=400; networks[3]['nombre']='Antena 3';
    // networks[4]['id']=378; networks[4]['nombre']='Cuatro';
    // networks[5]['id']=53; networks[5]['nombre']='TeleCinco';
    // networks[6]['id']=1016; networks[6]['nombre']='La Sexta';
    // networks[7]['id']=213; networks[7]['nombre']='Netflix';
    // networks[8]['id']=49; networks[8]['nombre']='HBO';
    // networks[9]['id']=593; networks[9]['nombre']='RTVE';

    
    
    
    
    let newDate = new Date()
    let day = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    if(day<10) day="0"+day; 
    if(month<10) month="0"+month; 

    let newDate2 = new Date();
    newDate2.setDate(newDate2.getDate()+parseInt(7));
    let day2 = newDate2.getDate();
    let month2 = newDate2.getMonth() + 1;
    let year2 = newDate2.getFullYear();
    if(day2<10) day2="0"+day2; 
    if(month2<10) month2="0"+month2; 


    // caracter para varias networks |
    // var url = URL_LIST_TV+'?air_date.gte='+year+'-'+month+'-'+day+'&air_date.lte='+year2+'-'+month2+'-'+day2+API_KEY_ALT+API_LANG+REGION+'&language=es-ES&sort_by=popularity.desc&timezone=Europe%2FMadrid&with_networks='+networks[0]['id'];
    var url_programacion = "https://grids.its-not.tv/api/getTVgrid_day?date="+year+month+day+"&country=ES";

    fetch(url_programacion)
    .then(res =>  res.json())
    .then(
      (result) => {
        this.setState({
          isLoaded: true,
          items: result.programs
          });
      },
      (error) => {
        this.setState({
            isLoaded: true,
            error
          });
        }
      )
    }

    render() {

      const { error, isLoaded, items } = this.state;
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div className="loading">Loading...</div>;
      } else {

        if(items){
          var aux;
          var canales = [];
          var programas = [];
          var canalesSeleccionados = CANALES_SELECCIONADOS;
          
          return (
                <div className="">
                  { /*********
                     *  Esta parte saca la programacion completa 
                     * que viene en el json y filtra por los que 
                     * vienen en el array de canales seleccionados
                     * Si hacemos sólo consulta al servicio por canal 
                     * nos ahorramos una petición que puede ralentizar
                     *********
                     
                     items.map(item => {
                      if(aux==item.channel){             
   
                      }
                      else{
                        aux = item.channel;
                        canales.push(item.channel);
                        console.log(item.channel)                        
                        // return (<div>{item.channel} <TVProgramacionProgramas channels={item.channel} /></div>)
                        if(canalesSeleccionados.indexOf(item.channel) > -1){
                          return (
                            <div><h2>{item.channel}</h2>
                            <TVProgramacionProgramas channels={item.channel} />
                          </div>
                          )
                        }
                      }
                    }
                  )      
                  */              
                  }

                  {
                    canalesSeleccionados.map( (canal, idx) => { 
                      return (
                        <div>
                          <h2>{canal}</h2>
                          <TVProgramacionProgramas channels={canal} />
                        </div>
                      )
                    })
                  }
                </div>
          );

        }
        else{
          return(<div><p>No hay programación para hoy</p></div>);
        }



      }
    }
  }

  export default TVProgramacion;
