import React, { Component } from 'react';
import { Image } from 'react-bootstrap'
import { 
  URL_SEARCH,
  URL_LIST,
  URL_TVSHOW,
  API_KEY, 
  API_KEY_ALT, 
  API_LANG, 
  URL_IMG, 
  IMG_SIZE_LARGE, 
  IMG_SIZE_SMALL, 
  IMG_SIZE_BEST, 
  RELEASE_DATE_DESC, 
  RELEASE_DATE_ASC,
  URL_GENRES_TV, 
  REGION} from '../../config/config';import Moment from 'react-moment';
import {Link, withRouter} from 'react-router-dom';

import Carousel from 'react-bootstrap/Carousel'

import '../../index.css';

import logo from "../../dog-min.png"

import TruncateMarkup from 'react-truncate-markup';

import { createBrowserHistory } from 'history';

//import SearchBar_own from '../SearchBar_own/';
import SearchBar from '../../containers/SearchBar';

const readMoreEllipsis = (
<span>
   {' '}...{' '}
</span>
);

class TopRatedTVShows extends Component {
  constructor(props) {
    super(props);
    var arrayGeneros = [];

    var urlGeneros = URL_GENRES_TV+'list'+API_KEY+API_LANG+REGION;

    fetch(urlGeneros)
    .then(res =>  res.json())
    .then(
      (result) => {
        

        result.genres.map((generoItem, contador) => {
          arrayGeneros[generoItem.id]=generoItem.name;
        });

      },
      (error) => {
        }
      )


    this.state = {
      films: [],
      error: null,
      isLoaded: false,
      query:'',
      items:[],
      generos: arrayGeneros
    }
  }

  componentDidMount() {

    var url = URL_TVSHOW+'top_rated'+API_KEY+API_LANG+REGION;

    fetch(url)
    .then(res =>  res.json())
    .then(
      (result) => {
        this.setState({
          isLoaded: true,
          items: result.results
          });
      },
      (error) => {
        this.setState({
            isLoaded: true,
            error
          });
        }
      )
    }

    render() {

      const { error, isLoaded, items, generos } = this.state;
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div className="loading">Loading...</div>;
      } else {
        return (
          
          <div className="container-fluid">
          <h2 className="text-center">Series mejor valoradas</h2>

          <div className="list-group w-100">
            {items.slice(0, 10).map((item, i) => {
              var imagen;
              imagen =
                item.poster_path != null
                  ? URL_IMG + IMG_SIZE_BEST + item.poster_path
                  : logo;
              return (
                <Link to={'/tvshow/'+item.id} params={{id:item.id}} >
                  <div
                    className="list-group-item list-group-item-action flex-column align-items-start"
                    key={item.id}
                  >
                    <div className="d-flex align-items-center">
                      <Image
                        className="image"
                        src={URL_IMG + IMG_SIZE_LARGE + item.poster_path}
                        width="45px"
                        responsive="true"
                      />

                      <div className="mr-3 ml-3 posicion">{i + 1}</div>
                      <div className="ms-3">
                        <p className="fw-bold mb-1">
                          {item.name} (
                          <Moment locale="es" parse="YYYY" format="YYYY">
                            {item.first_air_date}
                          </Moment>
                          )
                        </p>
                        <p className="text-muted mb-0">
                          <span>
                            <i className="fa fa-star text-warning"></i>{" "}
                            {item.vote_average}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
          
          
          
          
          

        );
      }
    }
  }

  export default TopRatedTVShows;
