import React, { Component, useState, useEffect, useContext } from "react";
import { Image } from "react-bootstrap";
import {
  LASTFM_URL_BASE,
  LASTFM_COUNTRY,
  LASTFM_API_KEY_ALT,
  LASTFM_METHOD_GEO_GETTOPARTISTS,
  LASTFM_METHOD_GEO_GETTOPTRACKS,
  LASTFM_METHOD_CHART_GETTOPTRACKS,
  LASTFM_URL_IMG,
  DEEZER_CHART_PODCASTS,
} from "../../config/config";
import Moment from "react-moment";
import { Link } from "react-router-dom";

import "../../index.css";

import { data } from "jquery";
import SpotifyContext from "../Spotify/SpotifyContext";

import datos from '../../../public/datos.json';

function Toptracks() {

 // let datos = require('../../../public/datos.json');

  const {
    token,
    genres,
    playlists,
    tracks,
    getToken,
    getGenres,
    getPlaylists,
    getTracks,
  } = useContext(SpotifyContext);

  useEffect(() => {

    getToken();
  }, []);

  useEffect(() => {
    //  getTracks("37i9dQZEVXbNFJfN1Vw8d9")
    //  getTracks("2z7k6r8z0OlXuDsIuy80ZN")
    getTracks("6brphE8nWZPYwQAXh8I03l");
  }, [token]);


  


  return (
      <div className="row">
        <div className="col-md-12 col-xs-12 col-sm ">
          <h2 className="text-center">Top Singles</h2>

          <div className="list-group w-100">
            {tracks.slice(0, 10).map((track, i) => {
              return (
                <a target="_blank" href={track.track.external_urls.spotify}>
                  <div
                    className="list-group-item list-group-item-action flex-column align-items-start"
                    key={track.id}
                  >
                    <div className="d-flex align-items-center">
                      <img
                        src={track.track.album.images[0].url}
                        alt=""
                        key={track.name}
                        width="67px"
                        height="67px"
                        className="mr-2"
                      />
                      <div className="mr-3 ml-3 posicion">{i + 1}</div>
                      <div className="ms-3">
                        <p className="fw-bold mb-1">{track.track.name}</p>
                        <p className="text-muted mb-0">
                          {track.track.artists.map((x) => x.name).join(", ")}
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              );
            })}
          </div>
        </div>

        
      </div>
  );
}

export default Toptracks;
