import React, { useState } from "react";
import { useEffect, useContext } from "react";
import axios from "axios";

import SpotifyContext from "../Spotify/SpotifyContext";

const AUTH_ENDPOINT = process.env.REACT_APP_AUTH_ENDPOINT;
const SPOTIFY_API = process.env.REACT_APP_SPOTIFY_API;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

function SpotifySearch() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = (event) => {
    getResults(event.target.value);

  };


  const {
    token,
    genres,
    playlists,
    tracks,
    results,
    resultsalbums,
    resultsartists,
    getToken,
    getGenres,
    getPlaylists,
    getTracks,
    getResults,
    getResultsAlbums,
    getResultsArtists,
    albumDetail,
    artistDetail,
    getArtistDetail
  } = useContext(SpotifyContext);

  useEffect(() => {

    getToken();
  }, []);

 


  return (
    <div className="">
      
      

<input className="form-control react-autosuggest__input"
        type="text"
        placeholder="Buscar por título de canción"
        onChange={handleSearch}
      />
      <br />
          <div className="list-group w-100">
          {results && results.items.map((result) => {
              return (
                <a target="_blank" key={result.id} href={result.external_urls.spotify}>
                  <div
                    className="list-group-item list-group-item-action flex-column align-items-start"
                    key={result.id}
                  >
                    <div className="d-flex align-items-center">
                      <img
                        src={result.album.images[0].url} 
                        alt={result.name}
                        key={result.name}
                        width="67px"
                        height="67px"
                        className="mr-2"
                      />
                      <div className="ms-3">
                        <p className="fw-bold mb-1">{result.name}</p>
                        <p className="text-muted mb-0">
                          {result.artists.map((x) => x.name).join(", ")}
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              );
            })}
          </div>







    </div>
  );
}

export default SpotifySearch;
