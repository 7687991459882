import React, { Component } from 'react';
import './LogoNetwork.css';

import { 
  URL_SEARCH, 
  API_KEY_ALT, 
  API_LANG,  
  URL_LIST,
  URL_LIST_TV,
  URL_LIST_TV_ONTHEAIR,
  API_KEY, 
  REGION, 
  URL_IMG, 
  IMG_SIZE_LARGE, 
  IMG_SIZE_SMALL, 
  IMG_SIZE_BEST, 
  URL_NETWORK_DETAIL } from '../../config/config';
  

class LogoNetwork extends Component {
  constructor(props){
    super(props);
    this.state = {
      images: null,
      error: null,
      isLoaded: false

    };
  }

  // componentWillMount(){}
   componentDidMount(){
      var url2 = URL_NETWORK_DETAIL+this.props.network+'/images'+API_KEY+API_LANG+REGION+'&language=es-ES';
      fetch(url2)
      .then(res2 => res2.json())
      .then(
          (result2) => {
              this.setState({ 
                images: result2,
                isLoaded:true
            })
          },
          (error2) => {
            this.setState({
                error
              });                  
          }
      )

   }
  // componentWillUnmount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    
    const { error, isLoaded, images } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loading">Loading...</div>;
    } else{
     //console.log(images.logos)
      if(images.logos[images.logos.length-1].file_path!=undefined)
      return(
        <><img width="30" src={URL_IMG+IMG_SIZE_SMALL+images.logos[0].file_path} /></>
      );
      else return (<div></div>)
    }

  }
}

export default LogoNetwork;