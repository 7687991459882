import React, { Component } from 'react';
import { compose } from 'recompose';

import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';

import '../../App.css';

//import Navigation from "./Navigation";
//import Film from "./Film/";
//import Films from "./Films/";
import Note from '../Note/Note';
import NoteForm from '../NoteForm/NoteForm';

/* react-bootstrap */
import Button from 'react-bootstrap/Button';
import Jumbotron from 'react-bootstrap/Jumbotron'

import Firebase, { FirebaseContext } from '../Firebase';


// db connection
//export const db = app('notes');

class Wall extends Component {

	constructor() {
		super();
		this.state = {
			notes: [
			//	{noteId: 1, noteContent: 'Note 1'},
			//	{noteId: 2, noteContent: 'Note 2'}
			]
		}
		this.addNote = this.addNote.bind(this);
		this.removeNote = this.removeNote.bind(this);


	}

	componentDidMount() {
		const { notes } = this.state;
		db.on('child_added', snap => {
			notes.push({
				noteId: snap.key,
				noteContent: snap.val().noteContent,
				createdby: snap.val().createdby,
				likes_count: snap.val().likes_count

			});

			this.setState({notes});
		});

		db.on('child_removed', snap => {
			for(let i = 0; i < notes.length; i++) {
				if(notes[i].noteId === snap.key) {
					notes.splice(i , 1);
				}
			}
			//console.log(notes);
			this.setState({notes});
		});

	}

	addNote(note) {
		/*
		let { notes } = this.state;
		notes.push({
			noteId: notes.length + 1,
			noteContent: note
		});
		this.setState({
			notes
		});
		*/

		db.push().set({
	    noteContent: note,
	    likes_count: 0,
	    likes : { juan: true, pepe:false
				}
	  });
	}

	removeNote(noteId) {
		db.child(noteId).remove();
	}

	render() {
		return (
			<div className="notesContainer">
				<div className="notesHeader">
				</div>
				<div className="notesBody">
					<Jumbotron className="bg-warning">
						<div >
							<h1 className="text-light">Hello, world!</h1>
							<p className="text-dark">
							This is a simple hero unit, a simple jumbotron-style
							</p>
						</div>
						<p>
						<Button variant="primary">Learn more</Button>
						</p>
					</Jumbotron>
				<div className="container">
					<div className="row">
					{
						this.state.notes.map((note,index) => {
						return (
							<Note
							noteContent={note.noteContent}
							noteId={note.noteId}
							createdby={note.createdby}
							key={note.noteId}
							likes_count={note.likes_count}
							removeNote={this.removeNote}
							indice={index+1}
							/>)
						})
					}
					</div>
				</div>
			</div>
			<div className="notesFooter">
				<div className="container">
					<div className="row">
						<div className="col-sm-12 col-md-12 text-center">
							<NoteForm addNote={this.addNote}/>
						</div>
					</div>
				</div>
			</div>
		</div>
		);
	}
}

export default Wall;
