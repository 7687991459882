import React, { Component } from 'react'
import { Image } from 'react-bootstrap'
import { URL_SEARCH, URL_DETAIL, URL_TVSHOW, API_KEY,API_KEY_ALT, API_LANG, URL_IMG, IMG_SIZE_LARGE, IMG_SIZE_SMALL, RELEASE_DATE_DESC, RELEASE_DATE_ASC } from '../../config/config'
import Moment from 'react-moment'

import { Tabs, Tab, TabContainer, TabContent, TabPane, Row, Col, Nav } from 'react-bootstrap'
// import Tab from 'react-bootstrap/Tab'
// import TabContainer from 'react-bootstrap/TabContainer'
// import TabContent from 'react-bootstrap/TabContent'
// import TabPane from 'react-bootstrap/TabPane'
// import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'
// import Nav from 'react-bootstrap/Nav'
import { Link, withRouter } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import Reparto from '../Reparto'
import Equipo from '../Equipo'
import ModalTvShow from '../ModalTvShow'
import StarRatingComponent from 'react-star-rating-component'
import Flag from "react-flags"
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { AuthUserContext } from '../Session'
import { withFirebase } from '../Firebase'
import { withAuthorization, withEmailVerification } from '../Session'
import '../../index.css'
import SearchBarTv from '../../containers/SearchBarTv'
import TvshowSeasons from '../TvshowSeasons'
import {isoLangs} from '../../constants/languages'

class Tvshow extends Component {
  constructor (props) {
    super(props);
    this.state = {
      tvshowId: props.match.params.tvshowId,
      comment:null,
      error: null,
      isLoaded: false,
    };
    



  }

  componentWillReceiveProps(nextProps) {

    var url = URL_TVSHOW+nextProps.match.params.tvshowId+API_KEY+API_LANG;
    fetch(url)
    .then(res =>  res.json())
    .then(
      (result) => {
        var countries = [];

        if(result.origin_country.length>0) { 
          for (var i = 0; i < this.state.origin_country.length; i++) {
            countries.push(result.origin_country[i]);

          }
        }
        else countries.push(result.original_language);
        this.setState({
          isLoaded: true,
          tvshowId: result.id,
          tvshowName: result.name,
          tvshowPoster: result.poster_path,
          tvshowOverview: result.overview,
          tvshowNameOriginal: result.original_name,
          tvshowPopularity: result.popularity,
          tvshowFirstAirDate: result.first_air_date,
          tvshowVoteAverage: result.vote_average,
          tvshowLanguage: result.original_language,
          origin_country: countries,
          number_of_seasons: result.number_of_seasons,
          networks: result.networks,
          genres:result.genres,
          tipo: 'tvshow'
        });

      },
      (error) => {
        this.setState({
            isLoaded: true,
            error
          });
        }
      );


  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (this.state.tvshowId !== prevState.tvshowId) {
      var url = URL_TVSHOW+this.state.tvshowId+API_KEY+API_LANG;
      fetch(url)
      .then(res =>  res.json())
      .then(
        (result) => {
          var countries = [];

          if(result.origin_country.length>0) { 
            for (var i = 0; i < result.origin_country.length; i++) {
              countries.push(result.origin_country[i]);

            }
          }
          else countries.push(result.original_language);
          this.setState({
            isLoaded: true,
            tvshowId: result.id,
            tvshowName: result.name,
            tvshowPoster: result.poster_path,
            tvshowOverview: result.overview,
            tvshowNameOriginal: result.original_name,
            tvshowPopularity: result.popularity,
            tvshowFirstAirDate: result.first_air_date,
            tvshowVoteAverage: result.vote_average,
            tvshowLanguage: result.original_language,
            origin_country: countries,
            number_of_seasons: result.number_of_seasons,
            networks: result.networks,
            genres:result.genres,
            tipo: 'tvshow'
          });

        },
        (error) => {
          this.setState({
              isLoaded: true,
              error
            });
          }
        );


    }
  }

  componentDidMount() {

    var url = URL_TVSHOW+this.state.tvshowId+API_KEY+API_LANG;

    fetch(url)
    .then(res =>  res.json())
    .then(
      (result) => {
        var countries = [];

        if(result.origin_country.length>0) { 
          for (var i = 0; i < result.origin_country.length; i++) {
            countries.push(result.origin_country[i]);
          }
        }
        else countries.push(result.original_language);

        this.setState({
          isLoaded: true,
          tvshowId: result.id,
          tvshowName: result.name,
          tvshowPoster: result.poster_path,
          tvshowOverview: result.overview,
          tvshowNameOriginal: result.original_name,
          tvshowPopularity: result.popularity,
          tvshowFirstAirDate: result.first_air_date,
          tvshowVoteAverage: result.vote_average,
          tvshowLanguage: result.original_language,
          origin_country: countries,
          number_of_seasons: result.number_of_seasons,
          networks: result.networks,
          genres:result.genres,
          tipo: 'tvshow'
        });
      },
      (error) => {
        this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }

  render() {

    const { error, isLoaded, items } = this.state;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loading">Loading...</div>;
    } else {

      var paises = [];
      for (var i = 0; i < this.state.origin_country.length; i++) {
        paises.push(<Flag key={i} basePath="../../img/flags" name={this.state.origin_country[i]}
          format="png"
          pngSize={16}
          shiny={false}
          alt=""
        />);
      }

      var tabSeasons = [];
      
      for (var i = 1; i <= this.state.number_of_seasons; i++) {   
        tabSeasons.push(<TvshowSeasons key={i} id={this.props.match.params.tvshowId} seasons={i} tipo={this.state.tipo} />);                  
      }

      var networksList = [];
      for (var i = 0; i < this.state.networks.length; i++) {  
        networksList.push(<p key={i}><Image title={this.state.networks[i].name} className="image" src={URL_IMG+IMG_SIZE_SMALL+this.state.networks[i].logo_path} width="25%" responsive="true" alt={this.state.networks[i].name} /></p>);
      } 

      let language = isoLangs[this.state.tvshowLanguage].nativeName;
      
      var generosList = [];
      for (var i = 0; i < this.state.genres.length; i++) {  
        //generosList.push(<p>{this.state.genres[i].name}</p>);
        
        generosList.push(<span className="badge badge-light mr-2" key={i}>
          <Link to={'/genretvshows/'+this.state.genres[i].id} params={{genreId:this.state.genres[i].id, genreName:this.state.genres[i].name}} >{this.state.genres[i].name} </Link>
        </span>);
      }  

      return (
        <div className="fakeClassParaLlevarNotesContainerAApp">

  			<div className="notesHeader">
          <SearchBarTv searchText={''} />
         </div>

  				<div className="notesBody" key={this.state.tvshowId} >
  					<div className="container-fluid">
              <div className="row">
                <div className="col-10">  
                  <h1 className="mb-2">{this.state.tvshowName}</h1>
                  <div className="float-left">
                        <StarRatingComponent
                          name="rate2"
                          editing={false}
                          starCount={10}
                          value={this.state.tvshowVoteAverage}
                        />
                    </div>
                    <div className="float-left">
                      <span className="badge badge-warning ml-2 mb-2 mr-2">{this.state.tvshowVoteAverage}</span>
                    </div>

                    <div className="float-left">
                      <p className="mb-2">{generosList}</p>
                    </div>
                </div>    

                <div className="col-2">
                  <AuthUserContext.Consumer>
                  {authUser =>
                      authUser ? (
                        <div>
                          <button type="button" className="btn btn-outline-light float-right" data-toggle="modal" data-target="#exampleModal">
                            <i className="fa fa-cog"></i>
                          </button>
                          <ModalTvShow tvshow={this.state.tvshowId} tvshowname={this.state.tvshowName} user={authUser} />
                        </div>
                      ) : (
                      <div>
                      </div>
                      )
                    }                  
                  </AuthUserContext.Consumer>
            </div>
          </div>

  						<div className="row">

                <div className='col-sm-4'>

                  <Image className="image" src={URL_IMG+IMG_SIZE_LARGE+this.state.tvshowPoster} width="100%" responsive="true" />
                  <p className="px-2 py-2 bg-warning text-dark">{this.state.tvshowNameOriginal} (<Moment locale="es" parse="YYYY" format="YYYY">{this.state.tvshowFirstAirDate}</Moment>)</p>
                  
                  
                  <div className="row">
                    <div className='col-12'>
                      <p>Popularidad: {this.state.tvshowPopularity}</p>
                      <p>Idioma original: {language}</p>
                      <p>Paises producción:&nbsp;{paises}</p>
                      <p>{networksList}</p>                       
                    </div>
                  </div>                
                  <br />
                  
                </div>
                <div className='col-sm-8'>

                  <p className="text-justify"> {this.state.tvshowOverview}</p>


                  <Tabs defaultActiveKey="cast" id="uncontrolled-tab-example">
                    <Tab eventKey="cast" title="Reparto">
                      <Reparto id={this.props.match.params.tvshowId} tipo={this.state.tipo} />
                    </Tab>
                    <Tab eventKey="crew" title="Equipo">
                      <Equipo id={this.props.match.params.tvshowId} tipo={this.state.tipo} />
                    </Tab>

                    <Tab eventKey="seasons" title={<span>Temporadas <span className="badge badge-primary mr-2">{this.state.number_of_seasons}</span></span>}>
                      <div className="accordion" id="accordionExample">
                       {tabSeasons}
                       </div>
                    </Tab>
                  </Tabs>
                </div>
               </div>
            </div>
          </div>



        </div>

      );
  }
  }
}

const condition = authUser => !!authUser;


/*export default compose(
  withAuthorization(condition),
)(Tvshow);*/

export default Tvshow;
