import React, { Component, useState, useEffect, useContext } from "react";
import { Image } from "react-bootstrap";
import {
  URL_SEARCH,
  API_KEY_ALT,
  API_LANG,
  URL_IMG,
  IMG_SIZE_LARGE,
  IMG_SIZE_SMALL,
  IMG_SIZE_BEST,
  RELEASE_DATE_DESC,
  RELEASE_DATE_ASC,
} from "../../config/config";
import Moment from "react-moment";
import { Link, withRouter } from "react-router-dom";


import "../../index.css";

import { createBrowserHistory } from "history";

import Toptracks from "../Toptracks";
import Toprecords from "../Toprecords";

import SpotifySearch from "../BuscadorSpotify";
import SpotifySearchAlbums from "../BuscadorSpotifyAlbums";

import Select from 'react-select';

import SpotifyContext from "../Spotify/SpotifyContext";








  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "blue" : "gray",
      height: "35px",
    }),
  };
  


  function Album(props){

   
    const [showSearchBar, setShowSearchBar] = useState("");
    const [showSearchBarTitle, setShowSearchBarTitle] = useState("");
  
    const onChangeSelect = (event) => {
      setShowSearchBar(event.value);
      setShowSearchBarTitle(event.title);
    };


    const {
      token,
      genres,
      playlists,
      tracks,
      resultsalbums,
      albumDetail,
      getToken,
      getGenres,
      getPlaylists,
      getTracks,
      getAlbumDetail
    } = useContext(SpotifyContext);
    
    useEffect(() => {
    
      getToken();
    }, []);
    
    useEffect(() => {
      //  getTracks("37i9dQZEVXbNFJfN1Vw8d9")
      //  getTracks("2z7k6r8z0OlXuDsIuy80ZN")
      getAlbumDetail(props.match.params.albumId);
      console.log("props",props.match.params.albumId)
    }, [token]);
    


const options = [
  {
    value: "albums",
    label: (
      <div>
        <span>&nbsp;Albums</span>
      </div>
    ),
    title: "Albums",
  },
  {
    value: "tracks",
    label: (
      <div>
        <span>&nbsp;Tracks</span>
      </div>
    ),
    title: "Tracks",
  },
  {
    value: "interprete",
    label: (
      <div>
        <span>&nbsp;Intérprete</span>
      </div>
    ),
    title: "Intérprete",
  },
];



const [error, setError] = useState("");
  const [isLoaded, setIsLoaded] = useState("");
  
  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  }

  return (
  

   
        <div className="">
          <div className="notesHeader">
            
          


          <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12 mt-2">
                <div className="">

                <Select
                  placeholder={
                    <div>
                      {" "}
                      {showSearchBarTitle ? (
                        showSearchBarTitle
                      ) : (
                        <span>¿Qué quieres buscar?</span>
                      )}{" "}
                    </div>
                  }
                  options={options}
                  styles={customStyles}
                  onChange={onChangeSelect}
                  value={showSearchBar}
                />
                
                </div>
              </div>
            
            <div className="col-12">
            {showSearchBar == "albums" ? (
              <div className="{showSearchBar}">
                <SpotifySearchAlbums />

              </div>
            ) : (
              ""
            )}
            {showSearchBar == "tracks" ? (
              <div className="{showSearchBar}">
                <SpotifySearch />  

              </div>
            ) : (
              ""
            )}
            {showSearchBar == "user" ? (
              <div className="{showSearchBar}">
                <SearchBarPerson searchText={""} />
              </div>
            ) : (
              ""
            )}
          </div>

</div>
            <div className="row">


     

              <div className="col-md-6 col-sm-6 col-xs-12 mt-4">
                <div className="container-fluid">  
                <h1>{albumDetail && albumDetail.name}</h1>

                
<Image className="image" src={albumDetail && albumDetail.images[0].url} width="75%" responsive="true" />

                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-xs-6 mt-4">
                <div className="container-fluid">
                <p>
                <strong><a class="link-warning" href={albumDetail && `/artist/${albumDetail.artists[0].id}`} >{albumDetail && albumDetail.artists[0].name}</a></strong> <span>({albumDetail && <Moment key={albumDetail.id} locale="es" parse="YYYY" format="YYYY">{albumDetail.release_date}</Moment>})</span> · <span>{albumDetail && albumDetail.total_tracks} tracks</span>

                  </p>
                  <p>{albumDetail && albumDetail.label}</p>
<strong>Tracklist</strong>

<div className="list-group w-100">

                  <ul>
{ albumDetail && albumDetail.tracks.items.map( (track) => {
  return   <div
  className="list-group-item list-group-item-action flex-column align-items-start"
  key={track.id}
>
  <div className="d-flex align-items-center">


    {track.name} ({  millisToMinutesAndSeconds(track.duration_ms)})</div></div>
})  }
</ul>


</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    
  
}

export default Album;
