import React, { Component } from 'react';
import { Image } from 'react-bootstrap'
import { 
  URL_SEARCH, 
  API_KEY_ALT, 
  API_LANG,  
  URL_LIST,
  URL_LIST_TV,
  URL_LIST_POPULAR,
  URL_LIST_TV_ONTHEAIR,
  API_KEY, 
  REGION, 
  URL_IMG, 
  IMG_SIZE_LARGE, 
  IMG_SIZE_SMALL, 
  IMG_SIZE_BEST, 
  RELEASE_DATE_DESC, 
  RELEASE_DATE_ASC } from '../../config/config';import Moment from 'react-moment';
import {Link, withRouter} from 'react-router-dom';

import Carousel from 'react-bootstrap/Carousel'

import '../../index.css';

import HorizontalScrollTV from '../HorizontalScrollTV';


import { createBrowserHistory } from 'history';

//import SearchBar_own from '../SearchBar_own/';
import SearchBar from '../../containers/SearchBar';

class EstrenosTV extends Component {
  constructor(props) {
    super(props);
    this.state = {
      films: [],
      error: null,
      isLoaded: false,
      query:''
    }
  }

  componentDidMount() {

    let newDate = new Date()
    let day = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    if(day<10) day="0"+day; 
    if(month<10) month="0"+month; 

    let newDate2 = new Date();
    newDate2.setDate(newDate2.getDate()+parseInt(7));
    let day2 = newDate2.getDate();
    let month2 = newDate2.getMonth() + 1;
    let year2 = newDate2.getFullYear();
    if(day2<10) day2="0"+day2; 
    if(month2<10) month2="0"+month2; 

    //var url = URL_LIST_TV+'?air_date.gte='+year+'-'+month+'-'+day+'&air_date.lte='+year+'-'+month+'-'+day+API_KEY_ALT+API_LANG+REGION+'&language=es-ES&sort_by=popularity.desc&timezone=Europe%2FMadrid&with_networks=2140|593|400|378|53|1016';
    //var url = URL_LIST_TV+'?air_date.gte='+year+'-'+month+'-'+day+'&air_date.lte='+year2+'-'+month2+'-'+day2+API_KEY_ALT+API_LANG+'&sort_by=popularity.desc&with_networks=2140|593|400|378|53|1016';
    //var url = URL_LIST_TV+'?air_date.gte='+year+'-'+month+'-'+day+'&air_date.lte='+year2+'-'+month2+'-'+day2+API_KEY_ALT+API_LANG+REGION+'&language=es-ES&sort_by=popularity.desc&timezone=Europe%2FMadrid&with_networks=2140|593|400|378|53|1016|213|49|443';
    var url = URL_LIST_POPULAR+'/tv/week?'+API_KEY_ALT+API_LANG+REGION+'&language=es-ES&sort_by=popularity.desc&timezone=Europe%2FMadrid';
    
    fetch(url)
    .then(res =>  res.json())
    .then(
      (result) => {
        this.setState({
          isLoaded: true,
          items: result.results
          });
      },
      (error) => {
        this.setState({
            isLoaded: true,
            error
          });
        }
      )
    }

    render() {


      const { error, isLoaded, items } = this.state;
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div className="loading">Loading...</div>;
      } else {

        if(items){
          return (
                <div className="">
                  <h2 className="text-center">Tendencias Televisión</h2>
                  <HorizontalScrollTV tvshows={items}/>
                </div>
          );
        }
        else{
          return(<div><p>No hay estrenos hoy</p></div>);
        }



      }
    }
  }

  export default EstrenosTV;
