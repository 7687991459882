import React, { Component }  from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import SignOutButton from './components/SignOut';
import LandingPage from './components/Landing';
import SignUpPage from './components/SignUp';
import SignInPage from './components/SignIn';
import AdminPage from './components/Admin';
import AccountPage from './components/Account';
import HomePage from './components/Home';
import PasswordForgetPage from './components/PasswordForget';
import Film from './components/Film';
import Tvshow from './components/Tvshow';
import Films from './components/Films';
import Tvshows from './components/Tvshows';
import Wall from './components/Wall';
import Person from './components/Person';
import Toptracks from './components/Toptracks';
import StarInfo from './components/StarInfo';
import { MovieContainer, MovieDetail, StarDetail } from './containers';
import { DisplayMsg } from './components/DisplayMsg';
import Landing from './components/Landing';
import Privacidad from './components/Privacidad';
import GenreFilms from './components/GenreFilms';
import GenreTVShows from './components/GenreTVShows';


import * as ROUTES from './constants/routes';
import * as ROLES from './constants/roles';
import TvshowSeasons from './components/TvshowSeasons';

import Charts from './components/Charts';
import Music from './components/Music';
import Album from './components/Album';
import Artist from './components/Artist';

export default (

        <Router>
        <Switch>
        <Route exact path="/" component={Landing} />
        <Route path="/list" component={Films}/>
        <Route path="/films" component={Films}/>
        <Route path="/genrefilms/:genreId" component={GenreFilms}/>
        <Route path="/genretvshows/:genreId" component={GenreTVShows}/>
        <Route path="/tvshows" component={Tvshows}/>
        <Route path="/film/:filmId" component={Film}/>
        <Route exact path="/tvshow/:tvshowId/:seasonId" component={TvshowSeasons}/>
        <Route path="/tvshow/:tvshowId" component={Tvshow}/>
        <Route path="/person/:personId" component={Person}/>
        <Route path="/music" component={Music}/>
        <Route path="/album/:albumId" component={Album}/>
        <Route path="/artist/:artistId" component={Artist}/>

        <Route path="/movie/:id" component={MovieDetail} />
        <Route path="/star/:id" component={StarDetail} />

        <Route path="/artist/:id" component={StarInfo} />

        <Route path="/search/:keyword" component={MovieContainer} />
        <Route path="/signin" component={SignInPage} />
        <Route path="/signup" component={SignUpPage} />
        <Route path="/home" component={HomePage} />

        <Route path="/account" component={AccountPage} />
        <Route path="/pw-forget" component={PasswordForgetPage} />

        <Route path="/admin" component={AdminPage} />

        <Route path="/privacidad" component={Privacidad} />

        

        <Route exact path={ROUTES.LANDING} component={LandingPage} />
        </Switch>
        </Router>

);
