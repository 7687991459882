import React, { Component } from 'react';
import { Image } from 'react-bootstrap'
import { 
  URL_SEARCH, 
  API_KEY_ALT, 
  API_LANG,  
  URL_LIST,
  URL_LIST_TV,
  URL_LIST_TV_ONTHEAIR,
  API_KEY, 
  REGION, 
  URL_IMG, 
  IMG_SIZE_LARGE, 
  IMG_SIZE_SMALL, 
  IMG_SIZE_BEST, 
  RELEASE_DATE_DESC, 
  POPULARITY_DESC,
  RELEASE_DATE_ASC,
  URL_NETWORK_DETAIL } from '../../config/config';
  import Moment from 'react-moment';
import {Link, withRouter} from 'react-router-dom';

import Carousel from 'react-bootstrap/Carousel'

import '../../index.css';

import HorizontalScrollTV from '../HorizontalScrollTV';
import TVProgramacionProgramas from '../TVProgramacionProgramas';


import { createBrowserHistory } from 'history';

//import SearchBar_own from '../SearchBar_own/';
import SearchBar from '../../containers/SearchBar';

class TabNetwork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      error: null,
      isLoaded: false,
      datosNetwork:null,
      images:null,
      query:''
    }
  }

  componentDidMount() {

    // let networks;
    // networks[0]['id']=2140; 
    // networks[0]['nombre']='#0';
    // networks[0]['canal']='0'
    // networks[1]['id']=443; 
    // networks[1]['nombre']='La 1';
    // networks[1]['canal']='La 1'
    // networks[2]['id']=1346; 
    // networks[2]['nombre']='La 2';
    // networks[2]['canal']='La 2'
    // networks[3]['id']=400; 
    // networks[3]['nombre']='Antena 3';
    // networks[3]['canal']='Antena 3';
    // networks[4]['id']=378; 
    // networks[4]['nombre']='Cuatro';
    // networks[4]['canal']='Cuatro'
    // networks[5]['id']=53; 
    // networks[5]['nombre']='TeleCinco';
    // networks[5]['canal']='Telecinco'
    // networks[6]['id']=1016; 
    // networks[6]['nombre']='La Sexta';
    // networks[6]['canal']='laSexta'
    // networks[7]['id']=213; 
    // networks[7]['nombre']='Netflix';
    // networks[7]['canal']='Netflix'
    // networks[8]['id']=49; 
    // networks[8]['nombre']='HBO';
    // networks[8]['canal']='HBO'
    // networks[9]['id']=593; 
    // networks[9]['nombre']='RTVE';
    // networks[9]['canal']='RTVE';


    
    let newDate = new Date()
    let day = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    if(day<10) day="0"+day; 
    if(month<10) month="0"+month; 

    let newDate2 = new Date();
    newDate2.setDate(newDate2.getDate()+parseInt(7));
    let day2 = newDate2.getDate();
    let month2 = newDate2.getMonth() + 1;
    let year2 = newDate2.getFullYear();
    if(day2<10) day2="0"+day2; 
    if(month2<10) month2="0"+month2; 
    
    


    // caracter para varias networks |
    var url = URL_NETWORK_DETAIL+this.props.network+API_KEY+API_LANG+REGION+'&language=es-ES';

    fetch(url)
    .then(res =>  res.json())
    .then(
      (result) => {
        this.setState({
          datosNetwork: result,
          isLoaded: true
          });
          
      },
      (error) => {
        this.setState({
            error
          });
        }
      )

      // var url2 = URL_NETWORK_DETAIL+this.props.network+'/images'+API_KEY+API_LANG+REGION+'&language=es-ES';
      //     fetch(url2)
      //     .then(res2 => res2.json())
      //     .then(
      //         (result2) => {
      //             this.setState({ 
      //               images: result2,
      //               isLoaded:true
      //           })
      //         },
      //         (error2) => {
      //           this.setState({
      //               error
      //             });                  
      //         }
      //     )

    // var url3 = URL_LIST_TV+API_KEY+API_LANG+REGION+'&air_date.gte='+year+'-'+month+'-'+day+'&air_date.lte='+year2+'-'+month2+'-'+day2+'&language=es-ES&with_networks='+this.props.network+RELEASE_DATE_DESC;
    

    if(this.props.network==213 ||this.props.network==49 ||this.props.network==1024 ||this.props.network==2552 ||this.props.network==2739 ||this.props.network==5081  ){
      var url3 = URL_LIST_TV+API_KEY+API_LANG+REGION+'&language=es-ES&with_networks='+this.props.network+POPULARITY_DESC;

      fetch(url3)
      .then(res3 =>  res3.json())
      .then(
        (result3) => {
          this.setState({
            items: result3.results,
            isLoaded: true,
                      
            });
        },
        (error) => {
          this.setState({
              error
            });
          }
        )
      }
    }




    render() {

      const { error, isLoaded, datosNetwork, images, items } = this.state;
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div className="loading">Loading...</div>;
      } else {

        if(datosNetwork && datosNetwork.name!=undefined ){
          var canal;
          if(datosNetwork.name != undefined ) canal = datosNetwork.name.replace("#", "");
                          
          return (
                <div className="">
                  <TVProgramacionProgramas channels={canal} />     
                    <div className="row">
                    {items && items.map(
                        (item,index )=>{
                          if(item.poster_path){
                          return(
                            <div className="col-3 view overlay network-poster" key={item.id}>
                            <div className="">
                            <Link to={'/tvshow/'+item.id} params={{id:item.id}} >
                            <img className="img-thumbnail" key={item.id} src={URL_IMG+IMG_SIZE_SMALL+item.poster_path} />
                            </Link>
                            </div>
                            </div>
                          );
                          }
                      }
                      )
                      
                    }
                      </div>
                </div>

          );

        }
        else{
          return(<div><p>No hay programación para hoy</p></div>);
        }



      }
    }
  }

  export default TabNetwork;
