import React, { Component } from 'react';
import { Image } from 'react-bootstrap'
import { 
  URL_SEARCH, 
  API_KEY_ALT, 
  API_LANG,  
  URL_LIST, 
  API_KEY, 
  REGION, 
  URL_IMG, 
  IMG_SIZE_LARGE, 
  IMG_SIZE_SMALL, 
  IMG_SIZE_BEST, 
  RELEASE_DATE_DESC, 
  RELEASE_DATE_ASC,
  POPULARITY_DESC,
  PRIMARY_RELEASE_DATE_DESC } from '../../config/config';
import Moment from 'react-moment';
import {Link, withRouter} from 'react-router-dom';

import Carousel from 'react-bootstrap/Carousel'

import '../../index.css';

import HorizontalScroll from '../HorizontalScroll';

import { createBrowserHistory } from 'history';

//import SearchBar_own from '../SearchBar_own/';
import SearchBar from '../../containers/SearchBar';

class Estrenos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      films: [],
      error: null,
      isLoaded: false,
      query:''
    }
  }

  componentDidMount() {

    let newDate = new Date()
    let day = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    if(day<10) day="0"+day; 
    if(month<10) month="0"+month; 

    this.setState({
      dia_hoy:day,
      mes_hoy:month,
      anyo_hoy:year
    })

    let newDate2 = new Date();
    newDate2.setDate(newDate2.getDate()+parseInt(7));
    let day2 = newDate2.getDate();
    let month2 = newDate2.getMonth() + 1;
    let year2 = newDate2.getFullYear();
    if(day2<10) day2="0"+day2; 
    if(month2<10) month2="0"+month2; 
    
    var url = URL_LIST+'?primary_release_date.gte='+year+'-'+month+'-'+day+'&primary_release_date.lte='+year2+'-'+month2+'-'+day2+API_KEY_ALT+API_LANG+POPULARITY_DESC;
        
    fetch(url)
    .then(res =>  res.json())
    .then(
      (result) => {
        this.setState({
          isLoaded: true,
          items: result.results
          });
      },
      (error) => {
        this.setState({
            isLoaded: true,
            error
          });
        }
      )
    }

    render() {

      const { error, isLoaded, items, dia_hoy, mes_hoy, anyo_hoy } = this.state;
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <div className="loading">Loading...</div>;
      } else {

        if(items){
          return (
                <div className="">
                <h2 className="text-center">Estrenos de esta semana</h2>
                <HorizontalScroll movies={items} dia_hoy={dia_hoy} mes_hoy={mes_hoy} anyo_hoy={anyo_hoy} />
              </div>
          );
        }
        else{
          return(<div><p>No hay estrenos esta semana</p></div>);
        }
      }
    }
  }

  export default Estrenos;
