import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { AuthUserContext } from '../Session';

import { withFirebase } from '../Firebase';
import  { FirebaseContext } from '../Firebase';

import { withAuthorization, withEmailVerification } from '../Session';

 class LikeMovieButton extends Component {

   constructor(props) {

     super(props);
     this.state = {
       filmId: props.film,
       error: null,
       like: null
     };

     this.getLikes=this.getLikes.bind(this);

   }

  componentDidMount() {

    this.getLikes(this.props.film, this.props.user.uid);
 
  }

  setLike(filmId,uid,status){
    var postData = { like:status};
    var newPostKey = this.props.firebase.db.ref().child('/users/' + uid + '/likes/').push().key;
    var updates = {};
    updates['/users/' + uid+'/likes/'+filmId] = postData;
    this.props.firebase.db.ref().update(updates);
    this.setState({like: status});
  }

  snapshotToArray(snapshot) {
    var returnArr = [];

    snapshot.forEach(function(childSnapshot) {
        var item = childSnapshot.val();
        item.key = childSnapshot.key;

        returnArr.push(item);
    });

    return returnArr;
  };


    getLikes = (filmId, uid) => { 
    var listadoLikes = [];
    
    this.props.firebase.db.ref('/users/' + uid).once('value')
    //.then( function(snapshot) {
    .then( (snapshot) => { 
      const likes = snapshot.val().likes;
     
      snapshot.forEach(
        //function(childSnapshot) {
        (childSnapshot) => { 
          let key = childSnapshot.key;
          let childData = childSnapshot.val();
          if(key==='likes'){
            listadoLikes = Object.keys(childData);
          }      
      });
     
      if(listadoLikes.indexOf(filmId.toString()) > 0){
        //console.log("like true", this.state);
        //this.state = {like: true };
        this.setState({like: true});
       // return <i className="far fa-heart text-danger"></i>
      }
      else {
        //console.log("like false", this.state);
        //this.state = {like: false };
        this.setState({like: false});
        //return <i className="fa fa-heart text-danger"></i>

      }
    });
  
  }

  render() {

    const { error, items, like } = this.state;

    if (error) {
      return <div>Error: {error.message}</div>;
    }  else {
      return( 
      <FirebaseContext.Consumer>
      {
        firebase => {    
        return(
          <div>
            <button onClick={() => { this.setLike(this.state.filmId, this.props.user.uid, !like) } } color="success" type="button" className="btn btn-outline-secondary" data-toggle="tooltip" data-placement="top" title="Mark as favourite">
            { 
              (like)? <i className="fa fa-heart text-danger"></i> : <i className="far fa-heart text-danger"></i>            
            }
            </button>

            {/*
            <button onClick={ () => { this.getLikes(this.state.filmId, this.props.user.uid) } } color="success" type="button" className="btn btn-outline-secondary" data-toggle="tooltip" data-placement="top" title="Mark as favourite">
              <i className="fa fa-eye text-info"></i>
            </button>
            */}

          </div>
          );
        }
      }
    </FirebaseContext.Consumer>);
  }
 }
}

const condition = authUser => !!authUser;


export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
)(LikeMovieButton);
