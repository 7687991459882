import React, { Component } from 'react';
import { Image } from 'react-bootstrap'
import { URL_SEARCH, URL_DETAIL, URL_PERSON,API_KEY,API_KEY_ALT, API_LANG, URL_IMG, IMG_SIZE_LARGE, IMG_SIZE_SMALL, IMG_SIZE_XSMALL, RELEASE_DATE_DESC, RELEASE_DATE_ASC } from '../../config/config';
import Moment from 'react-moment';

import PersonCredits from '../PersonCredits';

import '../../index.css';

//import logo from "../../dog-min.png"
import logo from "../../logo-boyanddog.png"

import SearchBarPerson from '../../containers/SearchBarPerson';

class Person extends Component {
  constructor(props) {

    super(props);
    this.state = {
      personId: props.match.params.personId,


      error: null,
      isLoaded: false,
    };
  }




  componentWillReceiveProps(nextProps) {


    var url = URL_PERSON+nextProps.match.params.personId+API_KEY+API_LANG;
    fetch(url)
    .then(res =>  res.json())
    .then(
      (result) => {
        this.setState({
          isLoaded: true,
          personId: result.id,
          personName: result.name,
          personPhoto: result.profile_path,
          personBirthday: result.birthday,
          personDeathday: result.deathday,
          personPlaceBirth: result.place_of_birth,
          personKnownForDepartment: result.known_for_department,
          personBiography: result.biography,


          tipo: 'person'
        });
      },
      (error) => {
        this.setState({
            isLoaded: true,
            error
          });
        }
      );


      var url_ext = URL_PERSON+nextProps.match.params.personId+'/external_ids'+API_KEY+API_LANG;
      fetch(url_ext)
      .then(res_ext =>  res_ext.json())
      .then(
        (result_ext) => {
          this.setState({
            isLoaded: true,
            personId: result_ext.id,
            person_imdb_id: result_ext.imdb_id,
            person_facebook_id: result_ext.facebook_id,
            person_twitter_id: result_ext.twitter_id,
            person_instagram_id: result_ext.instagram_id,
            tipo: 'person'
          });
        },
        (error_ext) => {
          this.setState({
              isLoaded: true,
              error
            });
          }
        );

  }


  componentDidUpdate(prevProps, prevState, snapshot) {

    if (this.state.personId !== prevState.personId) {

      var url = URL_PERSON+this.state.personId+API_KEY+API_LANG;
      fetch(url)
      .then(res =>  res.json())
      .then(
        (result) => {

          this.setState({
            isLoaded: true,
            personId: result.id,
            personName: result.name,
            personPhoto: result.profile_path,
          });
        },
        (error) => {
          this.setState({
              isLoaded: true,
              error
            });
          }
        );


        var url_ext = URL_PERSON+this.state.personId+'/external_ids'+API_KEY+API_LANG;
        fetch(url_ext)
        .then(res_ext =>  res_ext.json())
        .then(
          (result_ext) => {
            this.setState({
              isLoaded: true,
              personId: result_ext.id,
              person_imdb_id: result_ext.imdb_id,
              person_facebook_id: result_ext.facebook_id,
              person_twitter_id: result_ext.twitter_id,
              person_instagram_id: result_ext.instagram_id,
              tipo: 'person'
            });
          },
          (error_ext) => {
            this.setState({
                isLoaded: true,
                error
              });
            }
          );

    }
  }


  componentDidMount() {

    var url = URL_PERSON+this.state.personId+API_KEY+API_LANG;
    fetch(url)
    .then(res =>  res.json())
    .then(
      (result) => {

        this.setState({
          isLoaded: true,
          personId: result.id,
          personName: result.name,
          personPhoto: result.profile_path,
          personBirthday: result.birthday,
          personDeathday: result.deathday,
          personPlaceBirth: result.place_of_birth,
          personKnownForDepartment: result.known_for_department,
          personBiography: result.biography,
        });

      },
      (error) => {
        this.setState({
            isLoaded: true,
            error
          });
        }
      );


      var url_ext = URL_PERSON+this.state.personId+'/external_ids'+API_KEY+API_LANG;
      fetch(url_ext)
      .then(res_ext =>  res_ext.json())
      .then(
        (result_ext) => {
          this.setState({
            isLoaded: true,
            personId: result_ext.id,
            person_imdb_id: result_ext.imdb_id,
            person_facebook_id: result_ext.facebook_id,
            person_twitter_id: result_ext.twitter_id,
            person_instagram_id: result_ext.instagram_id,
            tipo: 'person'
          });
        },
        (error_ext) => {
          this.setState({
              isLoaded: true,
              error
            });
          }
        );

  }

  render() {


    const isDeath = this.state.personDeathday;
    let dead;
    let foto;
    if(isDeath){ dead = <span> / <Moment locale="es" parse="YYYY-MM-DD" format="DD-MM-YYYY">{this.state.personDeathday}</Moment> </span> }
    if(!this.state.personPhoto){ foto = logo;} else{ foto=URL_IMG+IMG_SIZE_LARGE+this.state.personPhoto;}

    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loading">Loading...</div>;
    } else {


      return (
      <div className="fakeClassParaLlevarNotesContainerAApp">
			   <div className="notesHeader">
         <SearchBarPerson searchText={''} />

         </div>
          <div className="notesBody">
				      <div className="container-fluid">
              <div className="row"><div className="col-sm-12">  <h1 className="mb-2">{this.state.personName}</h1></div></div>
					         <div className="row">
                   <div className='col-sm-4'>                   
                      <Image className="image" key={this.state.personId} src={foto} thumbnail />                       
                      <p className="mt-2">
                        <span><Moment locale="es" parse="YYYY-MM-DD" format="DD-MM-YYYY">{this.state.personBirthday}</Moment></span> <span> {this.state.personPlaceBirth} </span>
                        <span> {dead} </span>
                      </p>
                      <em>{this.state.personKnownForDepartment} </em>
                      {this.state.person_imdb_id && 
                        <p className="mt-2 ">
                          <a className="text-warning" href={'https://www.imdb.com/name/'+this.state.person_imdb_id} target="_blank">
                          <i className="text-warning fab fa-imdb"></i><span> Ver en IMDB</span></a>
                        </p>
                      }
                      {this.state.person_facebook_id && 
                      <p className="mt-2 ">
                        <a className="text-primary" href={'https://www.facebook.com/'+this.state.person_facebook_id} target="_blank">
                        <i className="text-primary fab fa-facebook"></i><span> Facebook</span></a>
                      </p>
                      }
                      {this.state.person_twitter_id && 
                      <p className="mt-2 ">
                        <a className="text-info" href={'https://www.twitter.com/'+this.state.person_twitter_id} target="_blank">
                        <i className="text-info fab fa-twitter"></i><span> Twitter</span></a>
                      </p>
                      }
                      {this.state.person_instagram_id && 
                        <p className="mt-2 ">
                        <a className="text-light" href={'https://www.instagram.com/'+this.state.person_instagram_id} target="_blank">
                        <i className="text-light fab fa-instagram"></i><span> Instagram</span></a>
                      </p>
                      }
                    </div>
                    <div className='col-sm-8'>
                        <span>{this.state.personBiography} </span>
                        <PersonCredits id={ this.state.personId}  />
                    </div>
                  </div>
                </div>
            </div>
        </div>

      );
    }
  }
}



export default Person;
