import React, { Component } from 'react';
//import { CastList} from '../components/CastList';
//import { TrailerList} from '../components/TrailerList';

import { CAST_MAX_NUM, TRAILER_MAX_NUM } from '../config/config';
import { Row, Col} from 'react-bootstrap';
//import { MovieInfo } from '../components/MovieInfo';
//import {  Poster } from '../components/Poster';
import { connect } from 'react-redux';
import { fetchMovieDetail, fetchCastList, fetchTrailerList} from '../actions';

class MovieDetail extends Component {

  componentDidMount() {
    const {dispatch} = this.props;
    fetchMovieDetail(this.props.match.params.id);
    fetchCastList(this.props.match.params.id);
    fetchTrailerList(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
     const {dispatch} = this.props;
     if(nextProps.match.params.id && this.props.match.params.id !== nextProps.match.params.id) {
         fetchMovieDetail(nextProps.match.params.id);
         fetchCastList(nextProps.match.params.id);
         fetchTrailerList(nextProps.match.params.id);
      }
  }

  // shouldComponentUpdate(nextProps, nextState){
  //     if(this.props.movie.id !== nextProps.movie.id) {
  //       //console.log('shouldComponentUpdate');
  //       return true;
  //     }
  //     return false;
  // }

  render() {
    console.log("dentro render",this.props);
    const {movie, casts, trailers, isFetcing_movie, isFetcing_casts, isFetcing_trailers} = this.props;

    if(isFetcing_movie || isFetcing_casts || isFetcing_trailers) {
      return <p>loading...</p>
    }
    if(movie.hasOwnProperty('id')) {
      return(
        <div>
          <Row>
            <Col xs={12} sm={6} md={4}>
              <Poster id={movie.id} path={movie.poster_path} responsive />
            </Col>
            <Col xs={12} sm={6} md={8}>
              <MovieInfo movie={movie}/>
              <CastList data={casts.slice(0,CAST_MAX_NUM)} />
            </Col>
          </Row>
          <Row>
            <TrailerList data={trailers.slice(0,TRAILER_MAX_NUM)} />
          </Row>
        </div>
      );
    } else
      return (null);

  }
}

function mapStateToProps(state){
  console.log("state",state);
  const {movieDetail, castList, trailerList} = state;
  const {isFetcing_movie, item: movie, error_movie} = movieDetail;
  const {isFetcing_casts, items: casts, error_casts} = castList;
  const {isFetcing_trailers, items: trailers, error_trailers} = trailerList;

  return {isFetcing_movie, movie, error_movie, isFetcing_casts, casts, error_casts, isFetcing_trailers, trailers, error_trailers}
}




export default connect(mapStateToProps)(MovieDetail);
