import React, { Component } from 'react';
import { Image } from 'react-bootstrap'
import {
  URL_SEARCH,
  API_KEY,
  API_KEY_ALT,
  API_LANG,
  URL_IMG,
  ANYO_ACTUAL,
  IMG_SIZE_LARGE,
  IMG_SIZE_SMALL,
  IMG_SIZE_BEST,
  RELEASE_DATE_DESC,
  RELEASE_DATE_ASC,
  VOTE_AVERAGE_DESC,
  URL_GENRES,
  REGION,
  URL_LIST
} from '../../config/config';
import Moment from 'react-moment';
import { Link, withRouter } from 'react-router-dom';

import Carousel from 'react-bootstrap/Carousel'

import '../../index.css';

import logo from "../../dog-min.png"

import TruncateMarkup from 'react-truncate-markup';

import { createBrowserHistory } from 'history';

//import SearchBar_own from '../SearchBar_own/';
import SearchBar from '../../containers/SearchBar';

const readMoreEllipsis = (
  <span>
    {' '}...{' '}
  </span>
);

class MoviesByGenre extends Component {
  constructor(props) {
    super(props);

    this.state = {
      films: [],
      error: null,
      isLoaded: false,
      query: '',
      genre: '',
      anyo: '',
      generos: [],
    };



    this.handleChange = this.handleChange.bind(this);
    this.handleChangeYear = this.handleChangeYear.bind(this);

  }




  handleChangeYear(event) {

    var anyo;
    this.setState(
      {
        anyo: event.target.value,
      }
    );

    //var genero = this.state.genre;
    var genero = this.state.genre > 0 ? this.state.genre : 18;


    if (event.target.value) anyo = event.target.value;
    else anyo = this.state.anyo;

    var url = URL_LIST + '?with_genres=' + genero + VOTE_AVERAGE_DESC + '&primary_release_year=' + anyo + API_KEY_ALT + API_LANG;

    fetch(url)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.results
          });
        },
        (error) => {
          this.setState({
            isLoaded: false,
            error
          });
        })
  }

  handleChange(event) {
    var genero;
    this.setState(
      {
        genre: event.target.value,
      }
    );

    var anyo = this.state.anyo > 0 ? this.state.anyo : ANYO_ACTUAL;

    //var anyo = 2020;

    if (event.target.value) genero = event.target.value;
    else genero = this.state.genre;

    var url = URL_LIST + '?with_genres=' + genero + '&sort_by=vote_average.desc&primary_release_year=' + anyo + API_KEY_ALT + API_LANG;
    fetch(url)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.results
          });
        },
        (error) => {
          this.setState({
            isLoaded: false,
            error
          });
        })
  }

  componentDidMount() {

    var urlGeneros = URL_GENRES + 'list' + API_KEY + API_LANG + REGION;
    var listaGeneros = [];
    let i=0;
    fetch(urlGeneros)
      .then(res => res.json())
      .then(
        (result) => {
          //listaGeneros = result.genres;
          for(i=0;i<result.genres.length ;i++){
            listaGeneros[result.genres[i].id]=result.genres[i].name;
          }

          this.setState({
            generos: listaGeneros,
          });
        },
        (error) => {
        }
      )

    var url = URL_LIST + "?primary_release_year=ANYO_ACTUAL" + VOTE_AVERAGE_DESC + API_KEY_ALT + API_LANG;

    fetch(url)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.results
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {

    const { error, isLoaded, items, generos } = this.state;
    var generoSeleccionado, generoSeleccionadoId;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loading">Loading...</div>;
    } else {

      return (
        <div className="col-md-12 col-sm-12 col-xs-12 mt-2">
          <h2 className="text-center">Películas por Género y Año</h2>
          <div className="row  ">

            <div className="col-6 mb-2 ">
              <div className="form-group">
                <select onChange={this.handleChange} value={this.state.genre} value={this.state.value} className="form-control" id="selectGenres">
                  <option value=""></option>
                  {generos.map((genero,idgenero) => {
                    if (this.state.genre == idgenero) { generoSeleccionado = genero; generoSeleccionadoId = idgenero; }
                    return (<option value={idgenero} key={idgenero}>{genero} </option>);
                  })
                  }
                </select>
              </div>
            </div>
            <div className="col-6 mb-2 ">
              <select onChange={this.handleChangeYear} value={this.state.year} value={this.state.value} className="form-control" id="selectYear">
                <option value=""></option>
                {(() => {
                  const options = [];
                  for (let i = ANYO_ACTUAL; i >= 1900; i--) {
                    options.push(<option value={i} key={i} >{i}</option>);
                  }
                  return options;
                })()}
              </select>
            </div>
          </div>

          <div className="row  ">

          <div className="col-md-12 col-sm-12 col-xs-12 mt-2">

            <div className="list-group w-100">
            {items.slice(0, 10).map((item, i) => {
              //console.log("item",item)
              var imagen;
              imagen = (item.poster_path != null) ? URL_IMG + IMG_SIZE_BEST + item.poster_path : logo;
              if (item.poster_path != null) {
                return (
                  <Link to={'/film/' + item.id} params={{ id: item.id }} >
                  <div className="list-group-item list-group-item-action flex-column align-items-start" key={item.id}>
                    
                    <div className='d-flex align-items-center'>
                      <img src={imagen} alt="" key={item.title} width="45px"  className='mr-2'/> 
                      <div className="mr-3 ml-3 posicion"></div>
                          <div className="ms-3">
                            <p className="fw-bold mb-1">{item.title}</p>
                            <p className="text-muted mb-0">{item.genre_ids.map((x) =>  {return <span className="badge badge-dark mr-2">{this.state.generos[x]}</span>;} ) }</p>
                          </div>

                    </div>

                    
                  </div>
                  </Link>

                )
              }
            }
            )}
            </div>
          </div>
          </div>

        </div>

      );
    }
  }
}

export default MoviesByGenre;
