import React, { Component } from 'react';
import { Image } from 'react-bootstrap'
import { URL_SEARCH, API_KEY_ALT, API_LANG, URL_IMG, IMG_SIZE_LARGE, IMG_SIZE_SMALL, IMG_SIZE_BEST, RELEASE_DATE_DESC, RELEASE_DATE_ASC } from '../../config/config';
import Moment from 'react-moment';
import {Link, withRouter} from 'react-router-dom';



import '../../index.css';

import { createBrowserHistory } from 'history';

//import SearchBar_own from '../SearchBar_own/';
import SearchBar from '../../containers/SearchBar';

class Charts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Charts: [],
      error: null,
      isLoaded: false,
      query:'marvel'
    }



  }
  componentDidMount() {
    var url = "https://api.themoviedb.org/3/trending/movie/week?api_key=a155a20f30c6119012f0a8c61891f1a9&language=es";
    var cadena=this.props.match.params.keyword;
  //  var url = URL_SEARCH+cadena+API_KEY_ALT+API_LANG;

    fetch(url)
    .then(res =>  res.json())
    .then(
      (result) => {
        this.setState({
          isLoaded: true,
          items: result.results
          });
      },
      (error) => {
        this.setState({
            isLoaded: true,
            error
          });
        }
      )
    }

  render() {
  // const history = createBrowserHistory();
  //  onst unlisten = history.listen((location, action) => {
  // location is an object like window.location
  // console.log(action, location.pathname, location.state);
  //    });


    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loading">Loading...</div>;
    } else {
      return (
        <div className="fakeClassParaLlevarNotesContainerAApp">


  			<div className="notesHeader">
        <SearchBar searchText={''} />
  				</div>
  				<div className="notesBody">
  					<div className="container-fluid">
            <h2>Cine</h2>
  						<div className="row">
                  {items.map(item => (

                    <div key={item.id} className="col-md-2 col-sm-2 h-100 mb-4 contenedor-pelicula">
                      <div className="col-md-12 col-sm-12">
                        <Link key={item.id} to={'/film/'+item.id} params={{id:item.id}}>
                          <Image className="image" key={item.id} src={URL_IMG+IMG_SIZE_BEST+item.poster_path} width="100%"  />
                        </Link>
                        <p className="titulo-pelicula px-2 py-2 bg-warning text-dark"  key={item.id}>
                        <Link key={item.id} to={'/film/'+item.id} params={{id:item.id}}>
                        {item.title} (<Moment key={item.id} locale="es" parse="YYYY" format="YYYY">{item.release_date}</Moment>)
                        </Link>
                      </p>
                      </div>
                  </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Charts;
