import React, { Component } from "react";
import { Image } from "react-bootstrap";
import {
  URL_SEARCH,
  API_KEY_ALT,
  API_LANG,
  CANALES_SELECCIONADOS,
  CANALES_ID_ITSNOTTV,
  URL_IMG,
  IMG_SIZE_LARGE,
  IMG_SIZE_SMALL,
  IMG_SIZE_BEST,
  RELEASE_DATE_DESC,
  RELEASE_DATE_ASC,
} from "../../config/config";
import Moment from "react-moment";
import { Link, withRouter } from "react-router-dom";

import "../../index.css";

import { createBrowserHistory } from "history";

//import SearchBar_own from '../SearchBar_own/';
import SearchBar from "../../containers/SearchBar";
import SearchBarTv from "../../containers/SearchBarTv";
import TVProgramacionProgramasAhora from "../TVProgramacionProgramasAhora";

import LogoNetwork from "../LogoNetwork";
import TabNetwork from "../TabNetwork";

class Tvshows extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tvshows: [],
      error: null,
      isLoaded: false,
      query: "marvel",
    };
  }
  componentDidMount() {
    var url =
      "https://api.themoviedb.org/3/trending/tv/week?api_key=a155a20f30c6119012f0a8c61891f1a9&language=es";
    var cadena = this.props.match.params.keyword;
    //  var url = URL_SEARCH+cadena+API_KEY_ALT+API_LANG;

    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.results,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    //  const history = createBrowserHistory();
    //  const unlisten = history.listen((location, action) => {
    // location is an object like window.location
    //      console.log(action, location.pathname, location.state);
    //    });

    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loading">Loading...</div>;
    } else {
      return (
        <div className="fakeClassParaLlevarNotesContainerAApp">
          <div className="notesHeader">
            <SearchBarTv searchText={""} />
          </div>
          <div className="notesBody">
            <div className="container-fluid">
              <div className="row">
                
                
                <div className="col-md-3 col-sm-12 col-xs-12 mt-2">
                <h2 className="text-center">Programación TV</h2>
                  <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    {/* <li className="nav-item"><a className="nav-link active" id="pills-topmovies-tab" data-toggle="pill" href="#pills-topmovies" role="tab" aria-controls="pills-topmovies" aria-selected="true">Top rated Movies</a></li> */}
                    {/* <li className="nav-item"><a className="nav-link" id="pills-toptvshows-tab" data-toggle="pill" href="#pills-toptvshows" role="tab" aria-controls="pills-toptvshows" aria-selected="false">Top rated TvShows</a></li> */}
                    <li className="nav-item"><a className="nav-link active" id="pills-cero-tab" data-toggle="pill" href="#pills-cero" role="tab" aria-controls="pills-cero" aria-selected="true"><LogoNetwork network="2140" /></a></li>
                    <li className="nav-item"><a className="nav-link" id="pills-la1-tab" data-toggle="pill" href="#pills-la1" role="tab" aria-controls="pills-la1" aria-selected="false"><LogoNetwork network="443" /></a></li>
                    <li className="nav-item"><a className="nav-link" id="pills-la2-tab" data-toggle="pill" href="#pills-la2" role="tab" aria-controls="pills-la2" aria-selected="false"><LogoNetwork network="1346" /></a></li>
                    <li className="nav-item"><a className="nav-link" id="pills-antena3-tab" data-toggle="pill" href="#pills-antena3" role="tab" aria-controls="pills-antena3" aria-selected="false"><LogoNetwork network="400" /></a></li>
                    <li className="nav-item"><a className="nav-link" id="pills-cuatro-tab" data-toggle="pill" href="#pills-cuatro" role="tab" aria-controls="pills-cuatro" aria-selected="false"><LogoNetwork network="378" /></a></li>
                    <li className="nav-item"><a className="nav-link" id="pills-telecinco-tab" data-toggle="pill" href="#pills-telecinco" role="tab" aria-controls="pills-telecinco" aria-selected="false"><LogoNetwork network="53" /></a></li>
                    <li className="nav-item"><a className="nav-link" id="pills-lasexta-tab" data-toggle="pill" href="#pills-lasexta" role="tab" aria-controls="pills-lasexta" aria-selected="false"><LogoNetwork network="1016" /></a></li>
                    
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    {/* <div className="tab-pane fade show active" id="pills-topmovies" role="tabpanel" aria-labelledby="pills-topmovies-tab"><TopRatedMovies /></div> */}
                    {/* <div className="tab-pane fade" id="pills-toptvshows" role="tabpanel" aria-labelledby="pills-toptvshows-tab"><TopRatedTVShows /></div> */}
                    <div className="tab-pane fade show active" id="pills-cero" role="tabpanel" aria-labelledby="pills-cero-tab"><div className="tab-tvnetwork"><TabNetwork network="2140" /></div></div>
                    <div className="tab-pane fade" id="pills-la1" role="tabpanel" aria-labelledby="pills-la-tab"><div className="tab-tvnetwork"><TabNetwork network="443" /></div></div>
                    <div className="tab-pane fade" id="pills-la2" role="tabpanel" aria-labelledby="pills-la2-tab"><div className="tab-tvnetwork"><TabNetwork network="1346" /></div></div>
                    <div className="tab-pane fade" id="pills-antena3" role="tabpanel" aria-labelledby="pills-antena3-tab"><div className="tab-tvnetwork"><TabNetwork network="400" /></div></div>
                    <div className="tab-pane fade" id="pills-cuatro" role="tabpanel" aria-labelledby="pills-cuatro-tab"><div className="tab-tvnetwork"><TabNetwork network="378" /></div></div>
                    <div className="tab-pane fade" id="pills-telecinco" role="tabpanel" aria-labelledby="pills-telecinco-tab"><div className="tab-tvnetwork"><TabNetwork network="53" /></div></div>
                    <div className="tab-pane fade" id="pills-lasexta" role="tabpanel" aria-labelledby="pills-lasexta-tab"><div className="tab-tvnetwork"><TabNetwork network="1016" /></div></div>
                    
                  </div>
                  <br />
                </div>


                <div className="col-md-3 col-sm-12 col-xs-12 mt-2">
                <h2 className="text-center">En Plataformas</h2>
                  <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    <li className="nav-item"><a className="nav-link active" id="pills-netflix-tab" data-toggle="pill" href="#pills-netflix" role="tab" aria-controls="pills-netflix" aria-selected="false"><LogoNetwork network="213" /></a></li>
                    <li className="nav-item"><a className="nav-link" id="pills-hbo-tab" data-toggle="pill" href="#pills-hbo" role="tab" aria-controls="pills-hbo" aria-selected="false"><LogoNetwork network="49" /></a></li>
                    <li className="nav-item"><a className="nav-link" id="pills-amazon-tab" data-toggle="pill" href="#pills-amazon" role="tab" aria-controls="pills-amazon" aria-selected="false"><LogoNetwork network="1024" /></a></li>
                    <li className="nav-item"><a className="nav-link" id="pills-apple-tab" data-toggle="pill" href="#pills-apple" role="tab" aria-controls="pills-apple" aria-selected="false"><LogoNetwork network="2552" /></a></li>
                    <li className="nav-item"><a className="nav-link" id="pills-disney-tab" data-toggle="pill" href="#pills-disney" role="tab" aria-controls="pills-disney" aria-selected="false"><LogoNetwork network="2739" /></a></li>
                    
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-netflix" role="tabpanel" aria-labelledby="pills-netflix-tab"><div className="tab-tvnetwork"><TabNetwork network="213" /></div></div>
                    <div className="tab-pane fade" id="pills-hbo" role="tabpanel" aria-labelledby="pills-hbo-tab"><div className="tab-tvnetwork"><TabNetwork network="49" /></div></div>
                    <div className="tab-pane fade" id="pills-amazon" role="tabpanel" aria-labelledby="pills-amazon-tab"><div className="tab-tvnetwork"><TabNetwork network="1024" /></div></div>
                    <div className="tab-pane fade" id="pills-apple" role="tabpanel" aria-labelledby="pills-apple-tab"><div className="tab-tvnetwork"><TabNetwork network="2552" /></div></div>
                    <div className="tab-pane fade" id="pills-disney" role="tabpanel" aria-labelledby="pills-disney-tab"><div className="tab-tvnetwork"><TabNetwork network="2739" /></div></div>
                    
                  </div>
                  <br />

                </div>


          


            

                  











              <div className="col-md-6 col-sm-12 col-xs-12 mt-2">

              <h2>Tendencias Series</h2>
              <div className="row">
                {items.map((item) => (
                    <div className="row">
                      <div
                        key={item.id}
                        className="col-md-12 col-sm-12 col-xs-12 mb-4"
                      >
                        <div className="row">
                          <div className="col-md-2 col-sm-4 col-xs-2">
                            <Link
                              key={item.id}
                              to={"/tvshow/" + item.id}
                              params={{ id: item.id }}
                            >
                              <Image
                                className="image w-100"
                                key={item.id}
                                src={URL_IMG + IMG_SIZE_BEST + item.poster_path}
                              />
                            </Link>
                          </div>
                          <div className="col-md-10 col-sm-8 col-xs-10">
                            <h3 key={item.id}>
                              <Link
                                className="link-warning"
                                key={item.id}
                                to={"/tvshow/" + item.id}
                                params={{ id: item.id }}
                              >
                                {item.name} (
                                <Moment
                                  key={item.id}
                                  locale="es"
                                  parse="YYYY"
                                  format="YYYY"
                                >
                                  {item.first_air_date}
                                </Moment>
                                )
                              </Link>
                            </h3>
                            <p>{item.overview}</p>
                          </div>
                        </div>
                      </div>
                    </div>))}
              </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      );
    }
  }
}

export default Tvshows;
