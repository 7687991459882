import React, { Component } from 'react';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import MessageList from './MessageList';

class Messages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      loading: false,
      messages: [],
      limit: 10,
    };
  }

  componentDidMount() {
    this.onListenForMessages();
  }

  onListenForMessages = () => {
    this.setState({ loading: true });

    this.props.firebase
      .messages()
      .orderByChild('createdAt')
      .limitToLast(this.state.limit)
      .on('value', snapshot => {
        const messageObject = snapshot.val();

        if (messageObject) {
          const messageList = Object.keys(messageObject).map(key => ({
            ...messageObject[key],
            uid: key,
          }));

          this.setState({
            messages: messageList,
            loading: false,
          });
        } else {
          this.setState({ messages: null, loading: false });
        }
      });
  };

  componentWillUnmount() {
    this.props.firebase.messages().off();
  }

  onChangeText = event => {
    this.setState({ text: event.target.value });
  };

  onCreateMessage = (event, authUser) => {
    this.props.firebase.messages().push({
      text: this.state.text,
      userId: authUser.uid,
      createdAt: this.props.firebase.serverValue.TIMESTAMP,
    });

    this.setState({ text: '' });

    event.preventDefault();
  };

  onEditMessage = (message, text) => {
    this.props.firebase.message(message.uid).set({
      ...message,
      text,
      editedAt: this.props.firebase.serverValue.TIMESTAMP,
    });
  };

  onRemoveMessage = uid => {
    this.props.firebase.message(uid).remove();
  };

  onNextPage = () => {
    this.setState(
      state => ({ limit: state.limit + 10 }),
      this.onListenForMessages,
    );
  };

  render() {
    const { users } = this.props;
    const { text, messages, loading } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div>
          <h2><i className="fa fa-inbox"></i> Messages</h2>


            {loading && <div>Loading ...</div>}

            {messages && (

              <MessageList
                messages={messages.map(message => ({
                  ...message,
                  user: users
                    ? users[message.userId]
                    : { userId: message.userId },
                }))}
                onEditMessage={this.onEditMessage}
                onRemoveMessage={this.onRemoveMessage}
              />
            )}



            {messages && <div>There are <span className="badge badge-pill badge-dark">{messages.length}</span> messages</div>}
            {!messages && <div>There are no messages ...</div>}

            {!loading && messages && (
              <div>
              <button type="button" onClick={this.onNextPage}  className="btn btn-default">
                Show {this.state.limit} messages more
              </button>
              </div>
            )}
            <div className="notesFooter">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 col-md-12 text-center">
                    <div className="NoteForm">
                      <form className=""
                        onSubmit={event =>
                          this.onCreateMessage(event, authUser)
                        }
                      >
                        <div className="input-group">
                        <input className="form-control noteInput"
                          type="text"
                          value={text}
                          onChange={this.onChangeText}
                        />
                        <div className="input-group-append">
                          <button type="submit"  className="btn btn-default px-5"><i className="fa fa-paper-plane"></i></button>
                        </div>
                        </div>
                      </form>
                    </div>
                  </div>
              </div>
            </div>
          </div>

          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(Messages);
