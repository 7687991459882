import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';

import { withAuthorization, withEmailVerification } from '../Session';
import { UserList, UserItem } from '../Users';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';

const AdminPage = () => (
  <div className="fakeClassParaLlevarNotesContainerAApp">
  <div className="notesHeader"></div>
    <div className="notesBody">
      <div className="container">
        <div className="row">
        <div className="col-md-12 col-sm-12">

        <div>
          <h1>Admin</h1>
          <p>The Admin Page is accessible by every signed in admin user.</p>

          <Switch>
            <Route exact path={ROUTES.ADMIN_DETAILS} component={UserItem} />
            <Route exact path={ROUTES.ADMIN} component={UserList} />
          </Switch>
        </div>
  </div></div></div></div></div>
);

const condition = authUser =>
  authUser && authUser.roles.includes(ROLES.ADMIN);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(AdminPage);
