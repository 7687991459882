import React, { Component } from 'react';
import { Image } from 'react-bootstrap'
import { URL_SEARCH, API_KEY, REGION, API_KEY_ALT, ANYO_ACTUAL, API_LANG, URL_IMG, URL_LIST, URL_GENRES, IMG_SIZE_LARGE, IMG_SIZE_SMALL, IMG_SIZE_BEST, RELEASE_DATE_DESC, RELEASE_DATE_ASC } from '../../config/config';
import Moment from 'react-moment';
import {Link, withRouter} from 'react-router-dom';

import logo from "../../logo-boyanddog.png"


import '../../index.css';

import { createBrowserHistory } from 'history';

//import SearchBar_own from '../SearchBar_own/';
import SearchBar from '../../containers/SearchBar';

class GenreFilms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      films: [],
      error: null,
      isLoaded: false,
      genreId:'',
      genreName:'',
      generosList:[]

    }

  }
  componentDidMount() {
    var generoId=this.props.match.params.genreId;
    var generoName;

    var urlGeneros = URL_GENRES+'list'+API_KEY+API_LANG+REGION;
    var listaGeneros = [];

    fetch(urlGeneros)
    .then(res =>  res.json())
    .then(
      (result) => {
        listaGeneros = result.genres;
        this.setState({
          generos: listaGeneros,
        });


      var generosList = [];
      for (var i = 0; i < listaGeneros.length; i++) {  
        if(listaGeneros[i].id == generoId) {
          generoName = listaGeneros[i].name;
        }  
        //generosList.push(<p>{this.state.genres[i].name}</p>);
        generosList.push(
        <span className="badge badge-light mr-2 mb-2" key={i}>
          <Link to={'/genrefilms/'+listaGeneros[i].id} params={{genreId:listaGeneros[i].id, genreName:listaGeneros[i].name}} >{listaGeneros[i].name} </Link>
        </span>);
      }

      this.setState(
        { 
          genreId: this.props.match.params.genreId,
          genreName: generoName,
          generosList
        }
      );

      },
      (error) => {
        }
      )

    var url = URL_LIST+"?with_genres="+generoId+"&sort_by=vote_average.desc&primary_release_year="+ANYO_ACTUAL+API_KEY_ALT+API_LANG;

    fetch(url)
    .then(res =>  res.json())
    .then(
      (result) => {
        this.setState({
          isLoaded: true,
          items: result.results
          });
      },
      (error) => {
        this.setState({
            isLoaded: true,
            error
          });
        }
      )
    
    }

    componentDidUpdate(prevProps) {
      
      if(prevProps.match.params.genreId != this.props.match.params.genreId){
        var generoId=this.props.match.params.genreId;
        var generoName; 
        var urlGeneros = URL_GENRES+'list'+API_KEY+API_LANG+REGION;
        var listaGeneros = [];
    
        fetch(urlGeneros)
        .then(res =>  res.json())
        .then(
          (result) => {
            listaGeneros = result.genres;
            this.setState({
              generos: listaGeneros,
            });
       
            var generosList = [];
            for (var i = 0; i < listaGeneros.length; i++) {  
              if(listaGeneros[i].id == generoId) {
                generoName = listaGeneros[i].name;
              }  
              //generosList.push(<p>{this.state.genres[i].name}</p>);
              generosList.push(
              <span className="badge badge-light mr-2 mb-2" key={i}>
                <Link to={'/genrefilms/'+listaGeneros[i].id} params={{genreId:listaGeneros[i].id, genreName:listaGeneros[i].name}} >{listaGeneros[i].name} </Link>
              </span>);
            }
            this.setState(
              { 
                genreId: this.props.match.params.genreId,
                genreName: generoName,
                generosList
              }
            )
          }
          )
    

        var url = URL_LIST+"?with_genres="+generoId+"&sort_by=vote_average.desc&primary_release_year="+ANYO_ACTUAL+API_KEY_ALT+API_LANG;

        fetch(url)
        .then(res =>  res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              items: result.results
              });
          },
          (error) => {
            this.setState({
                isLoaded: true,
                error
              });
            }
          )
          
      }
        
      }


  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loading">Loading...</div>;
    } else {

      return (
        <div className="fakeClassParaLlevarNotesContainerAApp">


  			<div className="notesHeader">
        <SearchBar searchText={''} />
  				</div>
  				<div className="notesBody">
  					<div className="container-fluid">
            <h1>Películas por género: {this.state.genreName}</h1>
            <div className="row">{this.state.generosList}</div>
            <br />

  						<div className="row">
                
                  {items.map(item => (

                    <div key={item.id} className="col-md-2 col-sm-2 h-100 mb-4">
                    <div className="">
                    <Link key={item.id} to={'/film/'+item.id} params={{id:item.id}}>
                    	<Image className="image" key={item.id} src={item.poster_path? URL_IMG+IMG_SIZE_BEST+item.poster_path: logo} width="100%" responsive="true  " />
                    </Link>
                    </div>
                    <p className="px-2 py-2 bg-warning text-dark"  key={item.id}>
                    <Link key={item.id} to={'/film/'+item.id} params={{id:item.id}}>
                      {item.title} (<Moment key={item.id} locale="es" parse="YYYY" format="YYYY">{item.release_date}</Moment>)
                      </Link>
                    </p>

                  </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default GenreFilms;
