import React, { Component } from 'react';

import Moment from 'react-moment';
import 'moment/locale/es'

import RandomImage from '../RandomImage/index';


import './Message.css';

class MessageItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editText: this.props.message.text,
    };
  }

  onToggleEditMode = () => {
    this.setState(state => ({
      editMode: !state.editMode,
      editText: this.props.message.text,
    }));
    event.preventDefault();
  };

  onChangeEditText = event => {
    this.setState({ editText: event.target.value });
    event.preventDefault();
  };

  onSaveEditText = () => {
    this.props.onEditMessage(this.props.message, this.state.editText);

    this.setState({ editMode: false });
    event.preventDefault();
  };

  render() {
    const { message, onRemoveMessage } = this.props;
    const { editMode, editText } = this.state;

    return (
      <div className="col-md-3 col-sm-3">
      <div className="card Note">

      <form className="form-inline">
        {editMode ? (
          <input className="mr-sm-2 form-control"
            type="text"
            value={editText}
            onChange={this.onChangeEditText}
          />
        ) : (
          <div className="d-block w-100 ">
            <RandomImage topic={message.text} />
            <p className="card-text">{message.text} </p>
            <p className="card-text">{message.editedAt && <span className="d-flex " ><small>Last edit:  <Moment locale="es" format="DD/MM/YYYY hh:mm">{message.editedAt}</Moment></small></span>}</p>
          </div>
        )}

        {editMode ? (
          <div className="d-flex">
            <button onClick={this.onSaveEditText} className="btn btn-default "><i className="fa fa-save"></i></button>
            <button onClick={this.onToggleEditMode} className="btn btn-default "><i className="fa fa-redo"></i></button>
          </div>
        ) : (
          <div className="d-flex">
            <button onClick={this.onToggleEditMode} className="btn btn-default "><i className="fa fa-pen-nib"></i></button>
          </div>
        )}

        {!editMode && (
          <div className="d-flex">
            <button className="btn btn-default "
              type="button"
              onClick={() => onRemoveMessage(message.uid)}
            >
              <i className="fa fa-trash"></i>
            </button>
          </div>
        )}
        </form>
        </div>
        </div>

    );
  }
}

export default MessageItem;
