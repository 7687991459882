import React, { Component } from 'react';
import { Image } from 'react-bootstrap'
import { URL_SEARCH, URL_DETAIL, URL_PERSON,API_KEY,API_KEY_ALT, API_LANG, URL_IMG, IMG_SIZE_LARGE, IMG_SIZE_SMALL, IMG_SIZE_XSMALL, RELEASE_DATE_DESC, RELEASE_DATE_ASC } from '../../config/config';
import Moment from 'react-moment';


import {Link} from 'react-router-dom';

import '../../index.css';

import logo from "../../dog-min.png"


import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

class PersonCredits extends Component {
  constructor(props) {
    // console.log("PERSONCREDITS - constructor ");

    super(props);
    this.state = {
      personId: props.id,
      items: [],
      items2:[],
      error: null,
      isLoaded: false,
    };
    // console.log("props person credits", props);
    // console.log("this.state  person credits", this.state);

  }





  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log("PERSONCREDITS - componentDidUpdate ");
    //
    // console.log("componentDidUpdate: this.state.personId",this.state.personId);
    // console.log("componentDidUpdate: prevState.personId",prevState.personId);
    // console.log("componentDidUpdate: prevProps.id",prevProps.id);

    if (this.state.personId !== prevProps.id ) {
       // console.log("***************************************************entra component did update if");


       // Sacar créditos
       var url2 = URL_PERSON+prevProps.id+'/combined_credits'+API_KEY+RELEASE_DATE_DESC+API_LANG;
       fetch(url2)
       .then(res2 =>  res2.json())
       .then(
         (result2) => {
           this.setState({
             isLoaded: true,
             items: result2.cast,
             items2: result2.crew,
             personId: prevProps.id,

           });
             //console.log(result2);
         },
         (error) => {
           this.setState({
               isLoaded: true,
               error
             });
           }
         );

    }
}



  componentDidMount() {


    // Sacar créditos
    var url2 = URL_PERSON+this.props.id+'/combined_credits'+API_KEY+RELEASE_DATE_DESC+API_LANG;
    fetch(url2)
    .then(res2 =>  res2.json())
    .then(
      (result2) => {
        this.setState({
          isLoaded: true,
          items: result2.cast,
          items2: result2.crew,

        });
      },
      (error) => {
        this.setState({
            isLoaded: true,
            error
          });
        }
      );
  }

  render() {
    const { error, isLoaded, items, items2 } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loading">Loading...</div>;
    } else {


      items.sort(function(a, b) {
          if(a.release_date) a = new Date(a.release_date);
          else if(a.first_air_date) a = new Date(a.first_air_date);

          if(b.release_date) b = new Date(b.release_date);
          else if(b.first_air_date) b = new Date(b.first_air_date);

          return a>b ? -1 : a<b ? 1 : 0;
      });
      items2.sort(function(c,d) {

          if(c.release_date) c = new Date(c.release_date);
          else if(c.first_air_date) c = new Date(c.first_air_date);

          if(d.release_date) d = new Date(d.release_date);
          else if(d.first_air_date) d = new Date(d.first_air_date);

          return c>d ? -1 : c<d ? 1 : 0;

      });


      var nombre_original,fecha_lanzamiento, papel, tipo, poster;

      return (
          <div className=" person ">
                  <Tabs defaultActiveKey="cast" id="uncontrolled-tab-example">
                    <Tab eventKey="cast" title="Reparto">
                    
              {items.map((item,index) => {

                if(item.poster_path){

                  if(item.original_name){
                    nombre_original = item.original_name;
                    fecha_lanzamiento = item.first_air_date;
                    papel = (item.character)? item.character:item.department;
                    tipo = "tvshow";
                    if(item.poster_path) poster = URL_IMG+IMG_SIZE_XSMALL+item.poster_path;
                    else poster = logo;
                  }
                  else if(item.original_title){
                    nombre_original = item.original_title;
                    fecha_lanzamiento = item.release_date;
                    papel = (item.character)? item.character:item.department;
                    tipo = "film";
                    if(item.poster_path) poster = URL_IMG+IMG_SIZE_XSMALL+item.poster_path;
                    else poster = logo;
                  }
                  return(
                    <div key={index}>
                      <div className="container">
                
                      <div className="row p-2  mb-1">
                        <div className="col-4 my-auto ">
                          <Link to={'/'+tipo+'/'+item.id} params={{id:item.id}} >
                            <Image src={poster} thumbnail    />
                          </Link>
                        </div>
                        <div className="col-8 my-auto">
                          <div className="row">
                            <div className="col-12 my-auto ">
                              <Link to={'/'+tipo+'/'+item.id} params={{id:item.id}}>{nombre_original} (<Moment locale="es" parse="YYYY" format="YYYY">{fecha_lanzamiento}</Moment>)</Link>
                            </div>
                            <div className="col-12 my-auto ">
                            <p>{papel}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
                    </div>
                  );
                }
                else{
                  if(item.original_name){
                    nombre_original = item.original_name;
                    fecha_lanzamiento = item.first_air_date;
                    papel = (item.character)? item.character:item.department;
                    tipo = "tvshow";
                    if(item.poster_path) poster = URL_IMG+IMG_SIZE_XSMALL+item.poster_path;
                    else poster = logo;

                  }
                  else if(item.original_title){
                    nombre_original = item.original_title;
                    fecha_lanzamiento = item.release_date;
                    papel = (item.character)? item.character:item.department;

                    tipo = "film";
                    if(item.poster_path) poster = URL_IMG+IMG_SIZE_XSMALL+item.poster_path;
                    else poster = logo;

                  }

                  return(
                    <div key={index}>
                      <div className="container">
                
                      <div className="row p-2  mb-1">
                        <div className="col-4 my-auto ">
                          <Link to={'/'+tipo+'/'+item.id} params={{id:item.id}} >
                            <Image src={poster} thumbnail  width="53px;"  />
                          </Link>
                        </div>


                        <div className="col-8 my-auto">
                          <div className="row">
                            <div className="col-12 my-auto ">
                            <Link to={'/'+tipo+'/'+item.id} params={{id:item.id}}>{nombre_original} (<Moment locale="es" parse="YYYY" format="YYYY">{fecha_lanzamiento}</Moment>)</Link>
                            </div>
                            <div className="col-12 my-auto ">
                              <p>{papel}</p>
                            </div>
                          </div>
                        </div>

                      </div>
                      </div>
                    </div>

                  );
                }


            }
          )
        }                    
        </Tab>
        <Tab eventKey="crew" title="Equipo">
        
        {items2.map((item2,index2) => {
          if(item2.poster_path){
            //imagen = URL_IMG+IMG_SIZE_XSMALL+item2.poster_path;
            //console.log("imagen",imagen);

            if(item2.original_name){
              nombre_original = item2.original_name;
              fecha_lanzamiento = item2.first_air_date;
              papel = (item2.job)? item2.job:item2.department;
              tipo = "tvshow";
              if(item2.poster_path!=null) poster = URL_IMG+IMG_SIZE_XSMALL+item2.poster_path;
              else poster = logo;

            }
            else if(item2.original_title){
              nombre_original = item2.original_title;
              fecha_lanzamiento = item2.release_date;
              papel = (item2.job)? item2.job:item2.department;

              tipo = "film";
              if(item2.poster_path!=null) poster = URL_IMG+IMG_SIZE_XSMALL+item2.poster_path;
              else poster = logo;
            }



            return(
              <div key={index2}>
                <div className="container">
                
                <div className="row p-2  mb-1">
                  <div className="col-4 my-auto ">
                    <Link to={'/'+tipo+'/'+item2.id} params={{id:item2.id}} >
                      <Image src={poster} thumbnail    />
                    </Link>
                  </div>

                  <div className="col-8 my-auto">
                    <div className="row">
                      <div className="col-12 my-auto ">
                        <Link to={'/'+tipo+'/'+item2.id} params={{id:item2.id}}>{nombre_original} (<Moment locale="es" parse="YYYY" format="YYYY">{fecha_lanzamiento}</Moment>)</Link>
                      </div>
                      <div className="col-12 my-auto ">
                        <p>{papel}</p>
                      </div>
                    </div>
                  </div>


                </div>
                </div>
              </div>

            );
          }
          else{

            if(item2.original_name){
              nombre_original = item2.original_name;
              fecha_lanzamiento = item2.first_air_date;
              papel = (item2.job)? item2.job:item2.department;
              tipo = "tvshow";
              if(item2.poster_path!=null) poster = URL_IMG+IMG_SIZE_XSMALL+item2.poster_path;
              else poster = logo;

            }
            else if(item2.original_title){
              nombre_original = item2.original_title;
              fecha_lanzamiento = item2.release_date;
              papel = (item2.job)? item2.job:item2.department;

              tipo = "film";
              if(item2.poster_path!=null) poster = URL_IMG+IMG_SIZE_XSMALL+item2.poster_path;
              else poster = logo;
            }


            return(
              <div key={item2.index2}>
                <div className="container">
                <div className="row p-2  mb-1">
                  <div className="col-4 my-auto ">
                    <Link to={'/'+tipo+'/'+item2.id} params={{id:item2.id}} >
                      <Image src={poster} thumbnail  width="53px;"  />
                    </Link>
                  </div>
                  </div>


                    <div className="col-8 my-auto">
                      <div className="row">
                        <div className="col-12 my-auto ">
                        <Link to={'/'+tipo+'/'+item2.id} params={{id:item2.id}}>{nombre_original} (<Moment locale="es" parse="YYYY" format="YYYY">{item2.first_air_date}</Moment>)</Link>

                        </div>
                        <div className="col-12 my-auto ">
                        <p>{papel}</p>

                        </div>
                      </div>
                    </div>

                </div>
              </div>

            );

          }


      }
    )
  }
  </Tab>
</Tabs>
</div>

          );

        }
      }
    }


export default PersonCredits;
