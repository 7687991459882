import React, { Component } from 'react';
import { Image } from 'react-bootstrap'
import { URL_SEARCH, API_KEY_ALT, API_LANG, URL_IMG, IMG_SIZE_LARGE, IMG_SIZE_SMALL, IMG_SIZE_BEST, RELEASE_DATE_DESC, RELEASE_DATE_ASC } from '../../config/config';
import Moment from 'react-moment';
import {Link, withRouter} from 'react-router-dom';
import style from './style.css';


 export default function HorizontalScroll(props) {

    return(
      <div>
      <div id="container" className=" break-out">
      <div id="flex-scroll">

      {props.tvshows.map((item,index) => {

        if(item.poster_path){

          return(
            <div className="box text-dark mr-2" key={item.id}>
              <div className="image-container">
                <Link to={'/tvshow/'+item.id} params={{id:item.id}} >
                <img key={item.id} src={URL_IMG+IMG_SIZE_BEST+item.poster_path} />
                </Link>
              </div>
          </div>
        )
        }
      }
    )}


      </div>
    </div>


      </div>
    );
}
