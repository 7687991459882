import React, { Component } from 'react';
import { Image } from 'react-bootstrap'
import { URL_SEARCH, API_KEY_ALT, API_LANG, URL_IMG, IMG_SIZE_LARGE, IMG_SIZE_SMALL, IMG_SIZE_BEST, RELEASE_DATE_DESC, RELEASE_DATE_ASC } from '../../config/config';
import Moment from 'react-moment';
import {Link, withRouter} from 'react-router-dom';



import '../../index.css';

import { createBrowserHistory } from 'history';

//import SearchBar_own from '../SearchBar_own/';
import SearchBarTv from '../../containers/SearchBarTv';

class GenreTVShows extends Component {
  constructor(props) {
    super(props);
    this.state = {
      films: [],
      error: null,
      isLoaded: false,
      genreId:'',
      genreName:'',

    }



  }
  componentDidMount() {
var generoId=this.props.match.params.genreId;
var generoName=this.props.match.params.genreName;



     this.setState(
       { genreId: this.props.match.params.genreId,
         genreName: this.props.match.params.genreName,
         }
     );


    var url = "https://api.themoviedb.org/3/discover/tv?with_genres="+generoId+"&primary_release_year=2020&timezone=Europe%2FMadrid&sort_by=first_air_date.desc"+API_KEY_ALT+API_LANG;


    fetch(url)
    .then(res =>  res.json())
    .then(
      (result) => {
        this.setState({
          isLoaded: true,
          items: result.results
          });
      },
      (error) => {
        this.setState({
            isLoaded: true,
            error
          });
        }
      )
    }

  render() {

    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loading">Loading...</div>;
    } else {
      return (
        <div className="fakeClassParaLlevarNotesContainerAApp">
          <div className="notesHeader">
            <SearchBarTv searchText={''} />
  			  </div>
  				<div className="notesBody">
  					<div className="container-fluid">
              <h1>Programas de TV por género</h1>
  						<div className="row">
                  {items.map(item => {
                    if(item.poster_path){
  
                    return(
                    <div key={item.id} className="col-md-2 col-sm-2 h-100 mb-4">
                      <div className="">
                      <Link key={item.id} to={'/tvshow/'+item.id} params={{id:item.id}}>
                        <Image className="image" key={item.id} src={URL_IMG+IMG_SIZE_BEST+item.poster_path} width="100%" responsive="true  " />
                      </Link>
                      </div>
                      <p className="px-2 py-2 bg-warning text-dark"  key={item.id}>
                        <Link key={item.id} to={'/tvshow/'+item.id} params={{id:item.id}}>
                          {item.name} (<Moment key={item.id} locale="es" parse="YYYY" format="YYYY">{item.first_air_date}</Moment>)
                        </Link>
                      </p>
                    </div>
                    );
                  }
                }
              )
              }
            </div>
          </div>
        </div>
      </div>
      );
    }
  }
}

export default GenreTVShows;
