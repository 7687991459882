import React, { Component } from 'react';
import { Image } from 'react-bootstrap'
import { URL_SEARCH, API_KEY_ALT, API_LANG, URL_IMG, IMG_SIZE_LARGE, IMG_SIZE_SMALL, IMG_SIZE_BEST, RELEASE_DATE_DESC, RELEASE_DATE_ASC } from '../../config/config';
import Moment from 'react-moment';
import { Link, withRouter } from 'react-router-dom';
import style from './style.css';

export default function HorizontalScroll(props) {
    return(
      <div>
        <div id="container" className=" break-out">
          <div id="flex-scroll">
            {props.movies.map((item,index) => {
              if(item.poster_path){
                let partes_fecha = item.release_date.split("-");
                let estreno;
                if(partes_fecha[0]==props.anyo_hoy && partes_fecha[1]==props.mes_hoy && partes_fecha[2]==props.dia_hoy){
                  estreno = 1;
                }else if(partes_fecha[0]==props.anyo_hoy && partes_fecha[1]==props.mes_hoy && partes_fecha[2]==props.dia_hoy+1){
                  estreno = 2;
                }else{
                  estreno = 0;
                }

                if(estreno == 1 || estreno == 2){

                
                return(
                  <div className="box text-dark ml-2 mr-2" key={item.id}>
                    <div className="image-container">
                    {estreno == 1 && <span className="badge badge-danger badge-estreno">Estreno HOY</span>}
                    {estreno == 2 && <span className="badge badge-warning badge-estreno">Estreno MAÑANA</span>}
                      <Link to={'/film/'+item.id} params={{ id:item.id }} >
                        <img key={item.id} src={URL_IMG+IMG_SIZE_BEST+item.poster_path} />
                      </Link>
                      <p className="marco-inferior bg-dark p-2 text-light text-right">
                        {item.video && <span className="float-left mr-2"><i className="fa fa-video"></i></span>}
                        <span className="float-right text-light">
                          <i className="fa fa-ticket-alt"></i>
                          <span className="ml-2"><Moment locale="es" parse="YYYY-MM-DD" format="DD/MM">{item.release_date}</Moment></span>
                        </span>
                      </p>
                    </div>
                </div>
                )
                }
              }
            }
          )}
      </div>
    </div>
  </div>
    );
}