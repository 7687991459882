

// Initialize Firebase
export const DB_CONFIG = {
	apiKey: "AIzaSyDlLR7jTErtfdHUWLZEmRoRg8OllmumjDk",
	authDomain: "elperrorabioso-7faa1.firebaseapp.com",
	databaseURL: "https://elperrorabioso-7faa1.firebaseio.com",
	projectId: "elperrorabioso-7faa1",
	storageBucket: "elperrorabioso-7faa1.appspot.com",
	messagingSenderId: "1096326591229"
};

export const ANYO_ACTUAL = 2023;
export const CANALES_ID_ITSNOTTV = [2140, 443, 1346, 400, 378, 53, 1016];
export const CANALES_SELECCIONADOS = ['0', 'La 1', 'La 2', 'Antena 3', 'Cuatro', 'Telecinco', 'laSexta'];


export const URL_IMAGE_ITSNOTTV = "https://storage.googleapis.com/tvgrid_images/";
export const SIZE_IMAGE_ITSNOTTV = "_small.jpg";
/* UNSPLASH */
export const UNSPLASH_URL = "https://api.unsplash.com/photos/random?query=";
export const UNSPLASH_APP_ACCESS_KEY = "&client_id=9c3d6d35026b08a31e86397ff6fcd7363089a760d67b3938b64032640bc445f0" ;
export const APP_SECRET = "8754c948d38a0a6cb601883d509f64ec5415dff6c8bfc68da9cecd8d61f14e29" ;
export const CALLBACK_URL = "/";

/* THEMOVIEDATABASE */
export const URL_LIST = 'https://api.themoviedb.org/3/discover/movie';
export const URL_LIST_TV = 'https://api.themoviedb.org/3/discover/tv';
export const URL_LIST_UPCOMING = 'https://api.themoviedb.org/3/movie/upcoming';
export const URL_LIST_TV_ONTHEAIR = 'https://api.themoviedb.org/3/tv/on_the_air';
export const URL_LIST_POPULAR = 'https://api.themoviedb.org/3/trending';
export const URL_SEARCH = 'https://api.themoviedb.org/3/search/movie?query=';
export const URL_SEARCH_TV = 'https://api.themoviedb.org/3/search/tv?query=';
export const URL_SEARCH_PERSON = 'https://api.themoviedb.org/3/search/person?query=';
export const URL_DETAIL = 'https://api.themoviedb.org/3/movie/';
export const URL_TVSHOW = 'https://api.themoviedb.org/3/tv/';
export const URL_PERSON = 'https://api.themoviedb.org/3/person/';
export const URL_IMG = 'https://image.tmdb.org/t/p/';
export const URL_YOUTUBE = 'https://www.youtube.com/embed/';
export const URL_CAST = '/casts';
export const URL_VIDEO = '/videos';
export const IMG_SIZE_XSMALL = 'w45/';
export const IMG_SIZE_SMALL = 'w200/';
export const IMG_SIZE_LARGE = 'w342/';
export const IMG_SIZE_XLARGE = 'w1280/';
export const IMG_SIZE_BEST = 'w185_and_h278_bestv2/';
export const CAST_MAX_NUM = 5;
export const TRAILER_MAX_NUM = 3;
export const API_KEY = '?api_key=a155a20f30c6119012f0a8c61891f1a9';
export const API_KEY_ALT = '&api_key=a155a20f30c6119012f0a8c61891f1a9';
export const API_LANG = '&language=es';
export const RELEASE_DATE_DESC='&sort_by=release_date.desc';
export const RELEASE_DATE_ASC='&sort_by=release_date.asc';
export const PRIMARY_RELEASE_DATE_DESC='&sort_by=primary_release_date.desc';
export const PRIMARY_RELEASE_DATE_ASC='&sort_by=primary_release_date.asc';
export const VOTE_AVERAGE_DESC='&sort_by=vote_average.desc';
export const VOTE_AVERAGE_ASC='&sort_by=vote_average.asc';
export const POPULARITY_DESC='&sort_by=popularity.desc';
export const POPULARITY_ASC='&sort_by=popularity.asc';

export const URL_GENRES='https://api.themoviedb.org/3/genre/movie/';
export const URL_GENRES_TV='https://api.themoviedb.org/3/genre/tv/';
export const URL_NETWORK_DETAIL='https://api.themoviedb.org/3/network/';
export const REGION = '&region=ES';
/* LAST.FM */
export const LASTFM_URL_BASE='https://ws.audioscrobbler.com/2.0/?method='
export const LASTFM_COUNTRY='&country=spain'
export const LASTFM_APPLICATION_NAME='perro'
export const LASTFM_API_KEY='?api_key=ac3f83d63e619d975b56cdae471c22c6&format=json'
export const LASTFM_API_KEY_ALT='&api_key=ac3f83d63e619d975b56cdae471c22c6&format=json'
export const LASTFM_SHARED_SECRET='c63472aacf1921cda4f5302e1f5a9f81'
export const LASTFM_USER='juancalero'
export const LASTFM_METHOD_GEO_GETTOPARTISTS='geo.gettopartists'
export const LASTFM_METHOD_GEO_GETTOPTRACKS='geo.gettoptracks'
export const LASTFM_METHOD_CHART_GETTOPTRACKS='chart.gettoptracks'
export const LASTFM_URL_IMG='https://lastfm-img2.akamaized.net/i/u'
export const DEEZER_CHART_PODCASTS='https://api.deezer.com/chart/0/podcasts'
export const DEEZER_CHART_TRACKS='https://api.deezer.com/chart/0/tracks'
export const DEEZER_CHART_ALBUMS='https://api.deezer.com/chart/0/albums'


