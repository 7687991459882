import React, { Component } from 'react';
import { URL_YOUTUBE } from '../../config/config';
import PropTypes from 'prop-types';

 ///**
 //* Represents a trailer
 // @param {string} trailer - the address of trailer for YouTube api
 //*/

 class Trailer extends Component {

   constructor(props) {

     super(props);
     this.state = {
       filmId: props.trailer,
       error: null,
       isLoaded: false,
     };
   }

  componentDidMount() {
  var url = "https://api.themoviedb.org/3/movie/"+this.state.filmId+"/videos?api_key=a155a20f30c6119012f0a8c61891f1a9&language=es";
  fetch(url)
  .then(res =>  res.json())
  .then(
    (result) => {

      this.setState({
        isLoaded: true,
        items: result.results
        });
    },
    (error) => {
      this.setState({
          isLoaded: true,
          error
        });
      }
    );


  }

  render() {
    const { error, isLoaded, items } = this.state;
    let urlyoutube;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loading">Loading...</div>;
    } else {

      if( this.state.items.length >0){
        let trailers = this.state.items;
          return (
            <div>
            {trailers.map( (item,index) => {
                urlyoutube = URL_YOUTUBE+item.key;
                return(
                  <div key={item.key} className="embed-responsive embed-responsive-16by9 mb-2">
                    <iframe className="embed-responsive-item" scrolling="no"  type="text/html" src={urlyoutube} allowFullScreen></iframe>
                  </div>

                );
              }
            )

            }
            </div>
          );
      }
      else return <div className=""></div>;

  }


  }
}

export default Trailer;
