const spotifyReducer = (state, action) => {


    switch(action.type) {
      case 'GET_TOKEN':

        return {
          ...state,
          token: action.payload,
          loading: false,
        }
      case 'GET_GENRES':

        return {
          ...state,
          genres: action.payload,
          loading: false,
        }
      case 'GET_PLAYLISTS':
          return {
            ...state,
            playlists: action.payload,
            loading: false,
          }
      case 'GET_TRACKS':
            return {
              ...state,
              tracks: action.payload,
              loading: false,
            }
      case 'GET_RESULTS':
        return {
          ...state,
          results: action.payload,
          loading:false,
        }
      case 'GET_RESULTS_ALBUMS':
        return {
            ...state,
            resultsalbums: action.payload,
            loading:false,
          }
      case 'GET_RESULTS_ARTISTS':
        return {
            ...state,
            resultsartists: action.payload,
            loading:false,
          }
  
      case 'GET_ALBUM_DETAIL':
        return {
            ...state,
            albumDetail: action.payload,
            loading:false,
          }

      case 'GET_ARTIST_DETAIL':
          return {
              ...state,
              artistDetail: action.payload,
              loading:false,
            }
  
            
      case 'SET_LOADING':

        return {
          ...state,
          loading: true,
        }

        
      default:
        return state
    }
  };
  
  export default spotifyReducer;