import React from 'react';
import ReactDOM from 'react-dom';



import LogMonitor from 'redux-devtools-log-monitor'
import DockMonitor from 'redux-devtools-dock-monitor'



import App from './App';
import { MovieContainer, MovieDetail, StarDetail } from './containers';
import Firebase, { FirebaseContext } from './components/Firebase';



import './index.css';
import './App.css';

import * as serviceWorker from './serviceWorker';




ReactDOM.render(


    <FirebaseContext.Provider value={new Firebase()}>
      
      <App /> 
      
    </FirebaseContext.Provider>

  ,
  document.getElementById('root'),
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
