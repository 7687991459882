import React, { useState } from "react";
import { useEffect, useContext } from "react";
import axios from "axios";
import Moment from "react-moment";

import SpotifyContext from "../Spotify/SpotifyContext";

const AUTH_ENDPOINT = process.env.REACT_APP_AUTH_ENDPOINT;
const SPOTIFY_API = process.env.REACT_APP_SPOTIFY_API;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

function SpotifySearchArtists() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = (event) => {
    getResultsArtists(event.target.value);

  };


  const {
    token,
    genres,
    playlists,
    tracks,
    results,
    resultsalbums,
    resultsartists,
    getToken,
    getGenres,
    getPlaylists,
    getTracks,
    getResults,
    getResultsAlbums,
    getResultsArtists,
    albumDetail,
    artistDetail,
    getArtistDetail
  } = useContext(SpotifyContext);

  useEffect(() => {

    getToken();
  }, []);

 



  return (
    <div className="">
      
      

<input className="form-control react-autosuggest__input"
        type="text"
        placeholder="Buscar por nombre de artista"
        onChange={handleSearch}
      />
      <br />
          <div className="list-group w-100">
          {resultsartists && resultsartists.items.map((result) => {
             return (
                <a  key={result.id} href={`/artist/${result.id}`}>
                  <div
                    className="list-group-item list-group-item-action flex-column align-items-start"
                    key={result.id}
                  >
                    <div className="d-flex align-items-center">
                      
                      <div className="ms-3">
                        <p className="text-muted mb-0">
                          {result.name}
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              );
            })}
          </div>







    </div>
  );
}

export default SpotifySearchArtists;
